.product {
    position: relative;
}

.product__header {
    display: flex;
    align-items: center;
}

.product__image {
    display: block;
    width: 100%;
    height: 12rem;
    object-fit: contain;
}

.product__content {
    text-align: center;

    h3,
    p {
        @media only screen and (max-width: 767px) {
            font-size: 0.875rem;
        }
    }

    h3 {
        font-size: 1rem;
    }
}
