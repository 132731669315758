.w-auto {
    width: auto !important;
}

.w-sm-auto {
    @media only screen and (min-width: 576px) {
        width: auto !important;
    }
}

.w-md-auto {
    @media only screen and (min-width: 768px) {
        width: auto !important;
    }
}

.w-lg-auto {
    @media only screen and (min-width: 992px) {
        width: auto !important;
    }
}

.w-xl-auto {
    @media only screen and (min-width: 1200px) {
        width: auto !important;
    }
}

.w-xxl-auto {
    @media only screen and (min-width: 1400px) {
        width: auto !important;
    }
}

.w-content {
    width: 100%;
    width: fit-content !important;
}

.w-sm-content {
    @media only screen and (min-width: 576px) {
        width: 100%;
        width: fit-content !important;
    }
}

.w-md-content {
    @media only screen and (min-width: 768px) {
        width: 100%;
        width: fit-content !important;
    }
}

.w-lg-content {
    @media only screen and (min-width: 992px) {
        width: 100%;
        width: fit-content !important;
    }
}

.w-xl-content {
    @media only screen and (min-width: 1200px) {
        width: 100%;
        width: fit-content !important;
    }
}

.w-xxl-content {
    @media only screen and (min-width: 1400px) {
        width: 100%;
        width: fit-content !important;
    }
}
