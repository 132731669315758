#profile_form {
    float: left;
    margin: 0px;
    width: 100%;
}

#profile_form .form_label {
    width: 200px;
}

#profile_form .form_input {
    width: 542px;
}

#profile_form .form_input input {
    width: 520px;
}

#profile_form .form_input select {
    width: 542px;
}

#profile_form .form_dob .form_input select {
    width: auto;
    margin-right: 10px;
    float: left;
}

#profile_form .form_input input[type='radio'] {
    width: auto;
    float: left;
}

#profile_form .form_input .radio label.required::after {
    content: '';
}

#profile_form .site_btn {
    float: right;
    font-family: 'Roboto Slab', serif;
}
