.navbar {
    --bs-navbar-padding-x: 0;
    --bs-navbar-padding-y: 1rem;

    > .container {
        position: relative;
        gap: 0 0.5rem;
    }
}

.navbar__logo,
.navbar__cta,
.navbar__notifications,
.navbar__tokens,
.navbar__account,
.navbar__toggle,
.navbar__button {
    width: auto;
    order: 1;
}

.navbar__nav {
    flex: 1 0 100%;
    justify-self: flex-start;
    order: 3;
    width: 100%;
    padding-top: 1rem;

    @media only screen and (min-width: 1200px) {
        display: block !important;
        flex: 0 0 auto;
        order: 1;
        width: auto;
        padding-top: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: auto;
    }
}

.navbar__logo {
    margin-right: auto;

    @media only screen and (min-width: 1200px) {
        margin-right: 0;
    }
}

.navbar__cta {
    margin-left: auto;
    margin-right: auto;

    & ~ .navbar__nav {
        margin-left: 0;
    }
    img {
        @media only screen and (min-width: 1200px) {
            width: 9.375rem;
            height: auto;
        }
    }
}

.navbar__notifications,
.navbar__toggle {
    margin-left: auto;
}

.navbar__notifications {
    position: relative;
    display: flex;
    align-items: center;

    @media only screen and (min-width: 1200px) {
        margin-left: 0;
    }
}

.navbar__toggle {
    &:has(+ .navbar__logo) {
        margin-left: 0;
    }

    @media only screen and (min-width: 1200px) {
        display: none;
    }
}

.navbar__button {
    &:first-of-type {
        margin-left: auto;
    }
}
