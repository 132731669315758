/* Standard button  */
.btn {
    font-weight: 500;
    background: linear;
}

/* Extra, extra small button */
.btn-xxs {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    border-radius: 0.25rem;
}

/* Extra small button */
.btn-xs {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 0.25rem;
}

/* Small button */
.btn-sm {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
}

/* Large button */
.btn-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 0.5rem;
    text-transform: uppercase;
}

/* Extra large button */
.btn-xl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
}

/* Extra, extra large button with reduced padding */
.btn-xxl {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-family: var(--bs-font-alt);
    font-size: 1.75rem;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
    border-radius: 0.5rem;

    &.btn-xxl-consistent {
        font-size: 2.125rem;
    }

    @media only screen and (min-width: 768px) {
        font-size: 2.125rem;
    }
}

/* Solid, primary button */
.btn-primary {
    border-color: transparent;
    background-color: var(--bs-primary);
    color: var(--bs-primary-contrast);

    &,
    &:hover,
    &:focus,
    &:active,
    &:disabled,
    &.disabled {
        border-color: transparent !important;
        background-color: var(--bs-primary) !important;
        color: var(--bs-primary-contrast);
    }
}

/* Solid, primary gradient button */
.btn-primary-gradient {
    border-color: transparent;
    background-color: var(--bs-primary);
    background-image: var(--bs-gradient-primary);
    background-origin: border-box;
    background-repeat: no-repeat;
    color: var(--bs-primary-contrast);

    &:hover,
    &:focus,
    &:active {
        filter: brightness(90%);
        color: var(--bs-primary-contrast);
    }
}

/* Outlined, primary button */
.btn-primary-outline {
    border-color: var(--bs-primary);
    background-color: transparent;
    color: var(--bs-primary);

    &:hover,
    &:focus,
    &:active {
        border-color: var(--bs-primary);
        background-color: var(--bs-primary);
        color: var(--bs-primary-contrast);
    }
}

/* Solid, secondary button */
.btn-secondary {
    border-color: transparent;
    background-color: var(--bs-secondary);
    color: var(--bs-secondary-contrast);

    &,
    &:hover,
    &:focus,
    &:active,
    &:disabled,
    &.disabled {
        border-color: transparent !important;
        background-color: var(--bs-secondary) !important;
        color: var(--bs-secondary-contrast);
    }
}

/* Solid, secondary gradient button */
.btn-secondary-gradient {
    border-color: transparent;
    background-color: transparent;
    background-image: var(--bs-gradient-secondary);
    background-origin: border-box;
    background-repeat: no-repeat;
    color: var(--bs-secondary-contrast);

    &:hover,
    &:focus,
    &:active {
        filter: brightness(90%);
        color: var(--bs-secondary-contrast);
    }
}

/* Outlined, secondary button */
.btn-secondary-outline {
    border-color: var(--bs-secondary);
    background-color: transparent;
    color: var(--bs-secondary);

    &:hover,
    &:focus,
    &:active {
        border-color: var(--bs-secondary);
        background-color: var(--bs-secondary);
        color: var(--bs-secondary-contrast);
    }
}
