.form-control {
    --bs-form-select-bg-img: url('../../images/icons/select.svg');
    border-color: var(--bs-gray-200);
    font-weight: 300;

    &::placeholder {
        color: var(--bs-gray-300);
    }

    &:focus {
        box-shadow: var(--box-shadow-light);
    }

    &[type='search'] {
        padding-left: 1.5rem;
        background-image: url('../../images/icons/search.svg');
        background-position: 0.25rem center;
        background-repeat: no-repeat;
        background-size: 0.875rem auto;
        border-radius: 0.5rem;
    }

    &:is(select) {
        background-image: var(--bs-form-select-bg-img),
            var(--bs-form-select-bg-icon, none);
        background-repeat: no-repeat;
        background-position: right 0.75rem center;
        background-size: 16px 12px;
    }

    [data-bs-theme='dark'] & {
        --bs-form-select-bg-img: url('../../images/icons/select-white.svg');
        background-color: var(--bs-body-color);
        color: var(--bs-body-bg);
    }
}

.form-control-lg {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    height: calc(2.625rem + 2px);
    font-size: 1.125rem;

    &[type='search'] {
        border-radius: 0.75rem;
        background-size: 1rem auto;
    }
}

.form-control-wrapper {
    position: relative;
}

.form-control-icon {
    position: absolute;
    top: 50%;
    right: 0.25rem;
    transform: translateY(-50%);
    cursor: pointer;

    &,
    &:hover,
    &:active,
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

.form-control-icon {
    padding: 0;
    margin: 0;
    background: none;
    color: var(--bs-gray-400);
    border: none;
    right: 0.75rem;

    .form-control:valid + &,
    .form-control:invalid + &,
    .form-control.is-valid + &,
    .form-control.is-invalid + & {
        opacity: 0;
    }
}
