.loading {
    position: relative;

    &::before {
        position: absolute;
        left: 50%;
        width: 2rem;
        height: 2rem;
        top: 50%;
        border: 0.25rem solid var(--bs-gray-100);
        border-top: 0.25rem solid var(--bs-gray-200);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        animation: spin 2s linear infinite;
        content: '';
    }
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
