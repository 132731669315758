.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--zindex-modal);
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    background: rgba(0, 0, 0, 0.5);

    &.show {
        display: flex;
    }
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;

    &,
    &:hover,
    &:active,
    &:focus {
        color: var(--bs-body-color);
        font-size: 2rem;
        text-decoration: none;
    }
}
