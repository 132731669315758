.refer-a-friend {
    max-width: 24rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h1 b,
    h2 b {
        display: block;
        font-size: 1.875rem;
        font-weight: 700;
        line-height: 1;
        color: var(--bs-primary);

        [data-bs-theme='dark'] & {
            color: var(--bs-highlight-color);
        }
    }
}

.refer-a-friend__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    padding-left: 1rem;
    font-size: var(--font-size-md);
    color: var(--bs-gray-600);
    outline: none;
}
