.icon-badge {
    display: block;
    padding: 0;
    margin: 0;
    width: 4.5rem;
    font-size: 0.6875rem;
    font-weight: 300;
    line-height: 1.125;
    text-align: center;
    word-wrap: break-word;
    overflow: hidden;

    &::before {
        display: block;
        width: 100%;
        padding-top: 45%;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        content: '';
    }

    .lead {
        display: block;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 1;
        color: var(--bs-red-light);
    }
}

.icon-badge-token {
    &::before {
        background-image: url('../../images/icons/access-token-deal.svg');
    }
}
