.icon-card {
    display: grid;
    grid-template-columns: 5.25rem auto;
    grid-column-gap: 1rem;
    background-color: var(--bs-white);
    color: var(--bs-black);
    padding: 0.5rem;
    border-radius: 0.5rem;

    &.icon-card--lg {
        grid-template-columns: 8.25rem auto;
    }

    &:has(~ .icon-card) {
        margin-bottom: 2rem;
    }

    b {
        color: var(--bs-red-light);
        font-weight: 500;
    }
}

.icon-card__content {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    * {
        width: 100%;
    }
}

.icon-card__footer {
    grid-column: 1 / -1;
    margin-top: 1rem;
}

.icon-card__title {
    color: inherit;
    font-size: 1rem;
    margin-bottom: 1rem;
}

.icon-card__title--large {
    color: var(--bs-purple-dark);
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.25rem;
}

.icon-card__image {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 0.625rem;
}

.icon-card__button {
    display: block;
    width: fit-content;
    min-width: 8rem;
    margin-top: auto;
    margin-left: auto;
}

.icon-card__button b {
    color: var(--bs-green-neon);
}
