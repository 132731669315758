#login {
    position: relative;
    float: left;
    width: 180px;
    height: 70px;
    margin-left: 10px;
}

#login ul {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 0px;
    list-style: none;
}

#login a,
#login li {
    position: relative;
    float: left;
    width: 140px;
    padding: 0px 20px;
    font-size: 14px;
    font-weight: bold;
    line-height: 2.5;
    text-align: right;
}

#login ul li a {
    float: left;
    width: 100%;
    padding: 0px;
    margin: 0px;
}

#login ul li ul {
    width: 168px;
    padding: 5px;
}

#login ul li ul li {
    float: left;
    width: 100%;
    padding: 0px;
}

#login .fb_connectbtn {
    width: 152px;
    padding: 3px;
    margin: 10px 0px;
    font-size: 13px;
}

#login .fb_connectbtn img {
    height: auto;
    margin: 2px 5px;
}

#login .txt_link {
    padding: 0px;
    text-align: center;
    font-size: 12px;
    font-weight: normal;
    color: #999;
    line-height: 1;
    width: 100%;
}

#login-form,
#login ul li ul {
    position: absolute;
    top: 35px;
    left: 1px;
    z-index: 1;
    display: none;
    padding: 10px;
    background-color: #fff;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

#login-form {
    width: 158px;
}

#login-form input {
    line-height: 2;
    font-size: 14px;
    border-radius: 6px;
    width: 148px;
    padding: 5px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

#login-form input[type='submit'] {
    width: 158px;
    background-color: #000;
    margin: 0px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
}

#login-a,
#account-a {
    color: #fff;
    background-color: #000;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
    z-index: 2;
    text-decoration: none;
}

#account-a ul {
    display: none;
}

#account-a:hover > ul {
    display: block;
}

#account-a ul li:first-child {
    border-bottom: 1px solid #ccc;
}

#account-a ul li a {
    color: #333;
}

#account-a a {
    color: #fff;
}

#points-a {
    color: #000;
    background-color: #ddd;
    border-radius: 0px 0px 3px 3px;
}

#points-a ul {
    top: 34px;
    z-index: -1;
    display: none;
}

#points-a:hover > ul {
    display: block;
}

#points-a ul li a {
    float: right;
    width: auto;
    color: #fff;
    background-color: #333;
    padding: 1px 4px;
    border-radius: 12px;
    line-height: 1;
    margin-top: 10px;
    margin-left: 5px;
}

#points-a ul li a:hover {
    text-decoration: none;
}

#signup-a {
    color: #000;
    background-color: #ddd;
    border-radius: 0px 0px 3px 3px;
    cursor: pointer;
    text-decoration: none;
}
