.btn-account {
    position: relative;
    overflow: visible;
    display: block;
    width: 5rem;
    padding: 0;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1;
    text-align: center;
    transition: var(--transition-base);

    &,
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        border: none;
        color: var(--bs-blue);
        outline: none;
        text-decoration: none;
    }

    [data-bs-theme='dark'] &,
    [data-bs-theme='dark'] &:hover,
    [data-bs-theme='dark'] &:active,
    [data-bs-theme='dark'] &:focus {
        color: var(--bs-white);
    }

    > span {
        position: absolute;
        left: -0.5rem;
        right: -0.5rem;
        display: block;
        margin-top: 0.75rem;
    }
}

/* Temp fix to test Chrome inconsistencies */
.btn-account .avatar,
.btn-account .upload,
.btn-account img {
    display: block;
    width: auto;
    height: 2.125rem;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.btn-account .avatar {
    width: 2.125rem;
    object-fit: cover;
    border-radius: 50%;
}

.btn-account .upload {
    height: 2.625rem;
    margin-bottom: -0.5rem;
}
