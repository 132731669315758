.btn-admin {
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: var(--zindex-fixed);

    @media only screen and (max-width: 1199px) {
        display: none;
    }
}
