.reward,
.reward--success {
    --reward-background: var(--reward-primary-background);
    --reward-color: var(--reward-primary-color);
    --reward-price: var(--reward-primary-price);
}

@media only screen and (max-width: 767px) {
    .rewards [class*='col']:nth-of-type(2n) .reward {
        --reward-background: var(--reward-secondary-background);
        --reward-color: var(--reward-secondary-color);
        --reward-price: var(--reward-secondary-price);
    }

    .rewards [class*='col']:nth-of-type(2n + 1) .reward {
        --reward-background: var(--reward-primary-background);
        --reward-color: var(--reward-primary-color);
        --reward-price: var(--reward-primary-price);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .rewards [class*='col']:nth-of-type(4n) .reward,
    .rewards [class*='col']:nth-of-type(4n + 1) .reward {
        --reward-background: var(--reward-secondary-background);
        --reward-color: var(--reward-secondary-color);
        --reward-price: var(--reward-secondary-price);
    }

    .rewards [class*='col']:nth-of-type(4n + 2) .reward,
    .rewards [class*='col']:nth-of-type(4n + 3) .reward {
        --reward-background: var(--reward-primary-background);
        --reward-color: var(--reward-primary-color);
        --reward-price: var(--reward-primary-price);
    }
}

@media only screen and (min-width: 1200px) {
    .rewards [class*='col']:nth-of-type(2n) .reward {
        --reward-background: var(--reward-secondary-background);
        --reward-color: var(--reward-secondary-color);
        --reward-price: var(--reward-secondary-price);
    }

    .rewards [class*='col']:nth-of-type(2n + 1) .reward {
        --reward-background: var(--reward-primary-background);
        --reward-color: var(--reward-primary-color);
        --reward-price: var(--reward-primary-price);
    }
}
