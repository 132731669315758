.nomination-card {
    h2 {
        font-weight: 700;
        text-transform: uppercase;
    }

    .card-body {
        background: var(--listing-secondary-background);
        color: var(--listing-secondary-color);
    }
}
