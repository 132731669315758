.icon-link {
    display: block;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 0.125rem solid transparent;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25;
    text-align: center;

    &,
    &:hover,
    &:focus,
    &:active {
        color: inherit;
        text-decoration: none;
    }

    &:hover {
        opacity: 0.85;
    }

    &:active {
        opacity: 0.95;
    }

    &.active {
        font-weight: 700;
        border-color: inherit;
    }

    picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 3.25rem;
        margin-bottom: 0.5rem;
    }

    img {
        display: block;
        width: auto;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.icon-link-alt {
    font-weight: 700;
    text-transform: uppercase;

    &,
    &:hover,
    &:active,
    &:focus {
        background-color: transparent;
        color: var(--bs-blue);
    }
}

.icon-link-lg {
    picture {
        height: 6rem;
        margin-bottom: 1rem;
    }
}
