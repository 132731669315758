.list-tick {
    padding-left: 0.25rem;
    list-style: none;

    li::before {
        padding-right: 0.25rem;
        font-family: 'FontAwesome';
        color: var(--bs-green);
        content: '\f00c';
    }
}

.list-cross {
    padding-left: 0.25rem;
    list-style: none;

    li::before {
        padding-right: 0.25rem;
        font-family: 'FontAwesome';
        color: var(--bs-red);
        content: '\f00d';
    }
}

.list-checkbox {
    padding-left: 0;
    list-style: none;

    li {
        position: relative;
        min-height: 1.875rem;
        padding-left: 2.3125rem;
        margin-bottom: 1rem;
    }

    li::before {
        position: absolute;
        top: 0.0625rem;
        left: 0;
        width: 1.8125rem;
        height: 1.8125rem;
        background-image: url('../../images/icons/checkbox.svg');
        background-position: center;
        background-repeat: no-repeat;
        content: '';
    }
}

.list-pill {
    display: inline-flex;
    flex-wrap: wrap;
    list-style: none;

    li {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
        width: auto;
        height: 100%;
        padding: 0.5rem 1rem;
        margin-left: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;
        background: var(--bs-gray-100);
        color: var(--bs-black);
        font-weight: 400;

        > * {
            width: 100%;
        }

        [data-bs-theme='dark'] & {
            background: var(--bs-gray-800);
            color: var(--bs-white);
        }
    }
}

.list-highlight {
    list-style: none;

    li {
        padding: 0.0625rem 0.25rem;
        background: var(--bs-yellow-pale);
        color: var(--bs-red);
        font-size: 0.75rem;
        font-weight: 500;
        text-transform: uppercase;
    }
}

.list-join {
    list-style: none;
    padding-inline-start: 0;

    li {
        display: flex;
        width: 16rem;
        align-items: center;
        flex-wrap: wrap;

        &:not(:last-child)::after {
            display: block;
            width: 100%;
            height: 1.25rem;
            margin: 1rem 0;
            background-image: url('../../images/icons/chevron-lg.svg');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            content: '';
        }
    }

    h3 {
        width: 9rem;
        padding: 1rem;
        margin: 0;
    }
}
