.wheel {
    position: relative;
    width: 20rem;
    height: 20rem;
    margin-left: auto;
    margin-right: auto;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;

    &::before,
    &::after {
        position: absolute;
        left: 50%;
        z-index: 1;
        display: block;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
    }

    &::before {
        top: 50%;
        width: 1.5rem;
        height: 2.2rem;
        transform: translateX(-50%) translateY(calc(-50% - 0.375rem));
    }

    &::after {
        top: 0;
        width: 1rem;
        height: 2rem;
        transform: translateX(-50%);
    }

    canvas {
        position: absolute;
        top: 6%;
        bottom: 6%;
        left: 6%;
        right: 6%;
        z-index: 0;
        width: 88%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    button {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        width: 10rem;
        height: 10rem;
        border: 0.25rem solid darkred;
        background-color: red;
        color: white;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 10rem;
        border-radius: 50%;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
    }

    button:disabled {
        background-color: darkred;
        cursor: not-allowed;
    }

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        display: none;
        z-index: 3;
        width: 80%;
        padding: 2rem;
        border: 0.25rem solid darkgreen;
        background-color: green;
        color: white;
        text-align: center;
        border-radius: 2rem;
        transform: translateX(-50%) translateY(-50%);
    }
}

.wheel__button {
    position: static;
    &.top {
        margin-bottom: 1rem;
    }

    &.bottom,
    &.top-start,
    &.top-end,
    &.bottom-start,
    &.bottom-end {
        margin-top: 1rem;
    }

    @media only screen and (min-width: 1200px) {
        &.top-start,
        &.top-end,
        &.bottom-start,
        &.bottom-end {
            position: absolute;
            z-index: 2;
            margin-top: 0;
            margin-bottom: 0;
        }

        &.top-start {
            top: 0;
            left: 1rem;
        }

        &.top-end {
            top: 0;
            right: 1rem;
        }

        &.bottom-start {
            bottom: 0;
            left: 1rem;
        }

        &.bottom-end {
            bottom: 0;
            right: 1rem;
        }
    }
}
