.footer-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;

    .container {
        @media only screen and (min-width: 992px) {
            max-width: 960px;
        }

        @media only screen and (min-width: 1200px) {
            max-width: 960px;
        }
    }

    h3 {
        display: block;
        margin-bottom: 1.25rem;
        color: var(--bs-white);
        font-family: var(--bs-body-font-family);
        font-size: 1rem;
        font-weight: 600;

        @media only screen and (min-width: 992px) {
            font-size: 1.125rem;
        }
    }

    li {
        margin-bottom: var(--spacer-3);

        @media only screen and (min-width: 768px) {
            margin-bottom: var(--spacer-2);
        }

        &:last-of-type {
            margin-bottom: 0;

            @media only screen and (min-width: 768px) {
                margin-bottom: 0;
            }
        }
    }

    a {
        display: block;
        font-family: var(--bs-body-font-family);
        font-weight: 400;

        @media only screen and (min-width: 992px) {
            font-size: 0.875rem;
        }

        &,
        &:hover,
        &:active,
        &:visited {
            color: var(--bs-white);
            text-decoration: none;
        }
    }
}
