.picture-fill-mobile {
    display: block;

    @media only screen and (max-width: 767px) {
        & {
            margin-left: 1rem;
            margin-right: 1rem;
        }

        img {
            display: block;
            width: 100%;
            max-width: none;
        }
    }
}
