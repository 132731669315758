[data-rewards-theme='bingoport-com'] {
    /* Theme RGB values */
    --bs-primary-rgb: 54, 123, 246;
    --bs-primary-light-rgb: 82, 141, 247;
    --bs-primary-pale-rgb: 235, 244, 254;
    --bs-primary-contrast-rgb: var(--bs-white-rgb);

    /* Theme color variables */
    --bs-primary: rgb(var(--bs-primary-rgb));
    --bs-primary-light: rgb(var(--bs-primary-light-rgb));
    --bs-primary-pale: rgb(var(--bs-primary-pale-rgb));
    --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));

    /* Links */
    --bs-link-color: var(--bs-sky);

    /* Highlight color */
    --bs-highlight-color: var(--bs-green-neon);

    /* Border color */
    --bs-border-color: rgba(var(--bs-white-rgb), 0.125);

    /* Gradient color variables */
    --bs-gradient-primary: linear-gradient(
        -90deg,
        rgba(var(--bs-primary-rgb), 1) 0%,
        rgba(var(--bs-primary-light-rgb), 1) 100%
    );

    --bs-gradient-secondary: linear-gradient(
        180deg,
        rgba(var(--bs-white-rgb), 80%) 0%,
        rgba(var(--bs-white-rgb), 40%) 100%
    );

    /* Font sizes */
    --font-size-md: 1rem;

    @media only screen and (min-width: 768px) {
        --font-size-md: 1rem;
    }

    @media only screen and (min-width: 992px) {
        --font-size-md: 1rem;
    }

    /* Headings - Landing */
    --heading-landing-color: inherit;
    --heading-landing-highlight-color: var(--bs-highlight-color);
    --heading-landing-font-size: 1.375rem;
    --heading-landing-font-weight: 900;

    /* Listings - Primary */
    --listing-primary-background: url('../../images/backgrounds/bingoport-listing.webp')
        top center/100% 100%;
    --listing-primary-color: var(--bs-black);
    --listing-primary-bold: var(--bs-red-light);
    --listing-primary-link: var(--bs-primary);
    --listing-primary-terms: var(--bs-gray-600);
    --listing-primary-deposit: url('../../images/icons/deposit.svg');

    /* Listings - Secondary */
    --listing-secondary-background: url('../../images/backgrounds/bingoport-listing-alt.webp')
        top center/100% 100%;
    --listing-secondary-color: var(--bs-white);
    --listing-secondary-bold: var(--bs-gold);
    --listing-secondary-link: var(--bs-sky);
    --listing-secondary-terms: var(--bs-gray-100);
    --listing-secondary-deposit: url('../../images/icons/deposit-white.svg');

    /* Rewards  - Category */
    --category-background: url('../../images/backgrounds/bingoport-category.webp');
    --category-background-s: url('../../images/backgrounds/bingoport-category-s.webp');

    /* Rewards - Primary */
    --reward-primary-background: transparent;
    --reward-primary-color: var(--bs-white);
    --reward-primary-price: var(--bs-gold);

    /* Rewards - Secondary */
    --reward-secondary-background: transparent;
    --reward-secondary-color: var(--bs-white);
    --reward-secondary-price: var(--bs-gold);
}

[data-rewards-theme='bingoport-com'][data-bs-theme='dark'] {
    body {
        --bs-body-bg-rgb: 51, 9, 100;
        --bs-body-color-rgb: var(--bs-white-rgb);
        --bs-body-bg: rgb(var(--bs-body-bg-rgb));
        --bs-body-color: rgb(var(--bs-body-color-rgb));
        --bs-body-image: url('../../images/backgrounds/bingo-dark.webp');
        --bs-body-image-alt: url('../../images/backgrounds/bingo-dark-alt.webp');
        --bs-body-semi-transparent: rgba(var(--bs-black-rgb), 0.25);
    }
}
