.category {
    position: relative;
    background: var(--category-background);
    background-size: cover;
}

.category h3 {
    color: var(--bs-gray-500);
    margin: 0;
}

.category h3 b {
    color: var(--bs-body-color);
}

.category__label {
    position: absolute;
    bottom: 100%;
    left: 0;
    z-index: 2;
    padding: 0.125rem 1rem;
    background: var(--bs-primary);
    color: var(--bs-white);
    width: auto;
    margin: 0;
    font-size: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
}

.category__body {
    padding: 0.5rem;
}

.category__title {
    color: var(--bs-gray-500);
    margin: 0;
}

.category__title b {
    color: var(--bs-body-color);
}

.category__link {
    display: block;
    text-decoration: none;
    color: inherit;
}

.category__link:hover,
.category__link:active,
.category__link:focus {
    text-decoration: none;
    color: inherit;
}

.category__image {
    display: block;
    width: auto;
    height: 2.5rem;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 375px) {
    .category__image {
        height: 3rem;
    }
}

@media only screen and (max-width: 767px) {
    .category {
        margin-left: calc(-0.5 * var(--spacer-4));
        margin-right: calc(-0.5 * var(--spacer-4));
        background: var(--category-background-s);
    }
}

@media only screen and (min-width: 768px) {
    .category {
        text-align: center;
    }

    .category h3 {
        color: var(--bs-white);
    }

    .category h3 b {
        display: block;
    }

    .category__label {
        top: 0;
        bottom: auto;
    }

    .category__body {
        padding: 2rem 1rem 1rem;
    }

    .category__title b {
        display: block;
    }

    .category__image {
        height: 4rem;
    }
}

@media only screen and (min-width: 992px) {
    .category__image {
        height: 5rem;
    }
}
