.btn-notification {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    padding-top: 2.5rem;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1.75rem auto;
        transform: translate(-50%);
        content: '';
        pointer-events: none;
        transition: opacity var(--transition-base-duration)
            var(--transition-base-timing) var(--transition-base-delay);
    }

    &::before {
        z-index: 0;
        background-image: url('../../images/icons/notification.svg');
        opacity: 0;
    }

    &::after {
        z-index: 1;
        background-image: url('../../images/icons/notification-active.svg');
        opacity: 1;
    }

    &.collapsed::before {
        opacity: 1;
    }

    &.collapsed::after {
        opacity: 0;
    }

    &.active::before,
    &.active.collapsed::before {
        opacity: 0;
    }

    &.active::after,
    &.active.collapsed::after {
        opacity: 1;
    }

    span:not(.sr-only) {
        position: absolute;
        top: -0.4375rem;
        left: 50%;
        z-index: 2;
        width: 1.3125rem;
        height: 1.3125rem;
        padding-top: 0.1875rem;
        background-color: var(--bs-red-light);
        color: var(--bs-white);
        font-size: 0.6875rem;
        text-align: center;
        border-radius: 50%;
    }
}
