.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.img-rounded {
    border-radius: 0.5rem;
}

.img-round {
    border-radius: 50%;
}
