.nav-link {
    color: var(--bs-body-color);
    font-size: 0.9375rem;

    @media only screen and (min-width: 768px) {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.9375rem;
    }

    @media only screen and (min-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    &:hover,
    &:focus,
    &:active {
        color: var(--bs-body-color);
    }

    [data-bs-theme='dark'] &,
    body.bg-image & {
        font-weight: 400;
    }
}
