p,
ul,
ol,
dl,
.p {
    margin-block-start: 0;
    color: inherit;
    font-family: var(--bs-body-font-family);
    font-size: var(--font-size-md);
    font-weight: var(--bs-body-font-weight);
}

blockquote {
    padding-left: 1rem;
    border-left: 0.25rem solid var(--bs-gray-200);
}

hr {
    border-color: var(--bs-body-color);
}

b,
strong {
    font-weight: 500;
}

small {
    font-size: 87.5%;
}

.text-xxs {
    font-size: var(--font-size-xxs);
}

.text-xs {
    font-size: var(--font-size-xs);
}

.text-sm {
    font-size: var(--font-size-sm);
}

.text-md {
    font-size: var(--font-size-md);
}

.text-lg {
    font-size: var(--font-size-lg);
}

.text-xl {
    font-size: var(--font-size-xl);
}

.text-xxl {
    font-size: var(--font-size-xxl);
}
