.carousel-controls {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;

    button {
        font-size: 1.75rem;
        font-weight: 100;
        color: inherit;

        [data-bs-theme='light'] & {
            color: var(--bs-gray-300);
        }
    }

    data {
        margin-left: 1.75rem;
        margin-right: 1.75rem;
        font-size: 1.125rem;
        font-weight: 500;

        @media only screen and (min-width: 768px) {
            margin-left: 1rem;
            margin-right: 1rem;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}
