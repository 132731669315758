select.form-control:invalid,
.form-select:invalid {
    color: var(--bs-gray-300);
}

.form-select-special {
    display: flex;
    width: auto;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    align-items: stretch;
    justify-content: center;
    border-radius: 0.5rem;
    background-color: var(--bs-gray-100);

    label,
    select {
        padding: 0.5rem;
        margin: 0;
        background: none;
        border: none;
        color: var(--bs-gray-600);
        font-family: var(--bs-font-sans-serif);
        font-size: 0.875rem;
        font-weight: 300;
    }

    select {
        text-transform: lowercase;
        padding-right: 2rem;
    }

    select,
    select:focus,
    select:active {
        outline: none;
    }

    label ~ select,
    select ~ label {
        padding-left: 0;
    }
}
