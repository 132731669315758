.carousel--full {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
}

.carousel--image,
.carousel--poster {
    .carousel-item {
        display: block;
        padding-left: 13%;
        padding-right: 13%;
        z-index: 1;

        @media only screen and (min-width: 768px) {
            padding-left: 27.5%;
            padding-right: 27.5%;
        }

        @media only screen and (min-width: 1200px) {
            padding-left: 33%;
            padding-right: 33%;
        }

        &.active:not(.carousel-item-start):not(.carousel-item-end) {
            z-index: 2;
        }

        /* Idle - Center position */
        &.active:not(.carousel-item-start):not(.carousel-item-end),
        /* To next - Adjacent right to center */
        &.carousel-item-next,
        /* To previous - Adjacent left to center */
        &.carousel-item-prev {
            transform: translateX(0);

            @media only screen and (min-width: 768px) {
                transform: translateX(0);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateX(0);
            }
        }

        /* Idle - Adjacent right */
        &.active + .carousel-item:not(.carousel-item-next),
        /* To next - Offscreen right to adjacent right */
        &.carousel-item-next + .carousel-item,
        /* To previous - Center to adjacent right */
        &.active.carousel-item-end {
            transform: translateX(70%);

            @media only screen and (min-width: 768px) {
                transform: translateX(45%);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateX(34%);
            }
        }

        /* Idle - Offscreen right */
        &.active ~ .carousel-item:not(.active + .carousel-item):not(.carousel-item-next + .carousel-item),
        /* To previous - Adjacent right to offscreen right */
        &.active.carousel-item-end + .carousel-item {
            transform: translateX(140%);

            @media only screen and (min-width: 768px) {
                transform: translateX(90%);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateX(68%);
            }
        }

        /* Idle - Adjacent left */
        &:has(+ .active):not(.carousel-item-prev),
        /* To next - Center to adjacent left */
        &.active.carousel-item-start,
        /* To previous - Offscreen left to adjacent left  */
        &:has(+ .carousel-item + .active.carousel-item-end) {
            transform: translateX(-70%);

            @media only screen and (min-width: 768px) {
                transform: translateX(-45%);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateX(-34%);
            }
        }

        /* Idle - Offscreen left */
        &:has(+ .carousel-item ~ .active),
        /* To next - Adjacent left to offscreen left */
        &:has(+ .active.carousel-item-start) {
            transform: translateX(-140%);

            @media only screen and (min-width: 768px) {
                transform: translateX(-90%);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateX(-68%);
            }
        }
    }
}

.carousel--image {
    .carousel-item {
        &.active:not(.carousel-item-start):not(.carousel-item-end),
        &.carousel-item-next,
        &.carousel-item-prev {
            blockquote,
            p {
                opacity: 1;
            }
        }

        blockquote,
        p {
            transition: opacity 0.6s;
            opacity: 0;
        }
    }
}

.carousel--poster {
    .carousel-item {
        &.active:not(.carousel-item-start):not(.carousel-item-end),
        &.carousel-item-next,
        &.carousel-item-prev {
            img {
                transform: translateY(0) scale(1);

                @media only screen and (min-width: 768px) {
                    transform: translateY(0) scale(1);
                }

                @media only screen and (min-width: 1200px) {
                    transform: translateY(0) scale(1);
                }
            }
        }

        img {
            transform-origin: top center;
            transition: transform 0.6s;
            transform: translateY(0) scale(0.95);

            @media only screen and (min-width: 768px) {
                transform: translateY(-0.5rem) scale(0.95);
            }

            @media only screen and (min-width: 1200px) {
                transform: translateY(-0.75rem) scale(0.95);
            }
        }
    }
}
