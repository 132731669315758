.timer {
    position: relative;
    container: timer-container / inline-size;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.75rem;
    text-align: center;

    h2 {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--bs-black);

        @container timer-container (min-width: 0) {
            font-size: 3.5cqw;
            margin-bottom: 2cqw;
        }
    }

    ul {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 0;
        margin: 0;
        list-style: none;
    }

    li {
        position: relative;
        padding: 0 0.25rem;
        color: var(--bs-black);
        font-size: 0.875rem;
        font-weight: 300;
        text-align: center;

        @container timer-container (min-width: 0) {
            padding: 0 1cqw;
            font-size: 3.5cqw;
        }
    }

    li b,
    li::after {
        display: block;
        color: var(--bs-red-light);
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 0.75;

        @container timer-container (min-width: 0) {
            font-size: 8cqw;
        }
    }

    li:not(:last-child)::after {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(-50%);
        content: ':';
    }

    .btn {
        font-size: 1.125rem;

        @container timer-container (min-width: 0) {
            font-size: 5cqw;
        }
    }
}
