.enquiry-form {
    h3,
    legend {
        font-family: var(--bs-body-font-family);
        font-size: 1.25rem;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 1rem;
    }
}
