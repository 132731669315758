.article {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 0.0625rem solid var(--bs-gray-200);

    > a {
        display: block;
        text-decoration: none;
        color: inherit;
    }

    p {
        margin-bottom: 0;
    }
}
