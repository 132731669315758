.hero {
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: var(--bs-black-rgba);
        content: '';
    }

    & > .container {
        position: relative;
        z-index: 1;
    }

    h1,
    h2,
    h3 {
        font-family: 'Roboto Slab', sans-serif;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        padding: 0;
        border: none;
    }

    h1,
    h2 {
        font-size: 2rem;
        clear: both;
    }

    h3 {
        font-size: 1.875rem;
    }
}
