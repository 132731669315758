.thermometer {
    position: relative;
    width: 100%;
    max-width: 60rem;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (min-width: 1200px) {
        margin-top: 1rem;
    }

    &::before {
        position: absolute;
        content: '';
    }
}

.thermometer__title {
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    font-family: var(--bs-body-font-family);
    font-size: 1.25rem;
    font-weight: 900;
    line-height: 1;
    text-align: right;
    text-transform: uppercase;

    @media only screen and (min-width: 768px) {
        margin-top: 1rem;
        font-size: 3rem;
        line-height: 0.95;
    }

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        margin-left: -2rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 3.5rem;
    }
}

.thermometer__image {
    position: relative;
    display: block;
    margin-left: -15%;

    @media only screen and (min-width: 768px) {
        margin-top: -12.5%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1199px) {
        margin-left: -12.5%;
        margin-right: -2rem;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: -10%;
    }

    &::before,
    &::after {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        content: '';
    }

    &::before {
        z-index: -1;
        background-image: url('../../images/graphics/thermometer.webp');
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-image: url('../../images/graphics/thermometer-reflection.webp');
    }
}

.thermometer__meter {
    position: absolute;
    bottom: 30.8%;
    left: 24.5%;
    display: block;
    width: 53%;
    height: 0;
    padding-top: 3.5%;
    overflow: hidden;
    background-color: #e92121;
    font-size: 0;
    line-height: 0;
    text-indent: -9999%;
    transform-origin: left center;
    transform: rotate(-68.9deg) scaleX(0);

    &.animated {
        animation: 2s cubic-bezier(0, 0, 0.25, 1) fill;
        transform: rotate(-68.9deg) scaleX(1);
    }
}

@keyframes fill {
    0% {
        transform: rotate(-68.9deg) scaleX(0);
    }
    100% {
        transform: rotate(-68.9deg) scaleX(1);
    }
}
