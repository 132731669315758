.bingo-link {
    text-align: center;

    img {
        display: block;
        width: 100%;
        height: auto;
        padding: 0 0.25rem;
        margin-top: -50%;
        margin-left: auto;
        margin-right: auto;

        @media only screen and (min-width: 768px) {
            width: 4rem;
            margin-top: -2rem;
        }

        @media only screen and (min-width: 1200px) {
            width: 5rem;
            margin-top: -2.5em;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25;

        @media only screen and (min-width: 768px) {
            font-size: 0.875rem;
        }
    }

    a {
        display: block;
        color: inherit;
        text-decoration: none;
    }
}
