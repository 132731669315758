/* Page */
.page {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100vh;

    > :not(main) {
        flex: 0 0 auto;
    }

    > header {
        position: sticky;
        top: 0;
        z-index: calc(var(--zindex-sticky) + 1);

        body:not(.bg-body-image) & {
            background-color: var(--bs-body-bg);
        }
    }

    > main {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 0 0;
    }

    > aside {
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: var(--zindex-sticky);
        transform-origin: top center;
        transition: transform var(--transition-base-duration)
            var(--transition-base-timing);
    }
}

/* Container */
.container,
.container-fluid {
    --bs-gutter-x: var(--spacer-4);

    max-width: 1248px;

    &.container-xs {
        max-width: 480px;
    }

    &.container-sm {
        max-width: 768px;
    }

    &.container-md {
        max-width: 992px;
    }

    &.container-full {
        max-width: none;
    }
}

/* Rows */
.row {
    --bs-gutter-x: var(--spacer-4);
}

/**
 * Gutter small
 *
 * Reduce gutters on a row and its direct columns
 */

/* Reduce gutters on all screen widths */
.gutter-small {
    --bs-gutter-x: var(--spacer-2);
}

/* Reduce gutters up to standard mobile widths */
.gutter-small-xs {
    @media only screen and (max-width: 575px) {
        --bs-gutter-x: var(--spacer-2);
    }
}

/* Reduce gutters up to large mobile widths */
.gutter-small-sm {
    @media only screen and (max-width: 767px) {
        --bs-gutter-x: var(--spacer-2);
    }
}

/* Reduce gutters up to standard tablet widths */
.gutter-small-md {
    @media only screen and (max-width: 991px) {
        --bs-gutter-x: var(--spacer-2);
    }
}

/* Reduce gutters up to large tablet widths */
.gutter-small-lg {
    @media only screen and (max-width: 1199px) {
        --bs-gutter-x: var(--spacer-2);
    }
}

/**
 * Gutter none
 *
 * Remove gutters on a row and its direct columns
 */

/* Remove gutters on all screen widths */
.gutter-none {
    --bs-gutter-x: var(--spacer-0);
}

/* Remove gutters up to standard mobile widths */
.gutter-none-xs {
    @media only screen and (max-width: 575px) {
        --bs-gutter-x: var(--spacer-0);
    }
}

/* Remove gutters up to large mobile widths */
.gutter-none-sm {
    @media only screen and (max-width: 767px) {
        --bs-gutter-x: var(--spacer-0);
    }
}

/* Reduce gutters up to standard tablet widths */
.gutter-none-md {
    @media only screen and (max-width: 991px) {
        --bs-gutter-x: var(--spacer-0);
    }
}

/* Reduce gutters up to large tablet widths */
.gutter-none-lg {
    @media only screen and (max-width: 1199px) {
        --bs-gutter-x: var(--spacer-0);
    }
}

/**
 * Override grid
 *
 * Position child element over grid gutters
 */

/* Override grid on all screen widths */
.override-grid {
    margin-left: calc(var(--bs-gutter-x) * -0.5);
    margin-right: calc(var(--bs-gutter-x) * -0.5);
}

/* Override grid up to standard mobile widths */
.override-grid-xs {
    @media only screen and (max-width: 575px) {
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        margin-right: calc(var(--bs-gutter-x) * -0.5);
    }
}

/* Override grid up to large mobile widths */
.override-grid-sm {
    @media only screen and (max-width: 767px) {
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        margin-right: calc(var(--bs-gutter-x) * -0.5);
    }
}

/* Reduce gutters up to standard tablet widths */
.override-grid-md {
    @media only screen and (max-width: 991px) {
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        margin-right: calc(var(--bs-gutter-x) * -0.5);
    }
}

/* Reduce gutters up to large tablet widths */
.override-grid-lg {
    @media only screen and (max-width: 1199px) {
        margin-left: calc(var(--bs-gutter-x) * -0.5);
        margin-right: calc(var(--bs-gutter-x) * -0.5);
    }
}

/**
 * Additional grid classes
 *
 * Additional grid classes for more control over layout
 */

/* Adds ability to create two column layout for larger mobiles only */
.col-xs-6 {
    @media only screen and (min-width: 375px) and (max-width: 575px) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
