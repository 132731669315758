.rating {
    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }

    .list-inline-item {
        color: var(--bs-yellow);
        font-size: 1.25rem;
        margin-right: 0;
    }
}

.rating--small .list-inline-item {
    font-size: 0.875rem;

    @media only screen and (min-width: 768px) {
        font-size: 1.25rem;
    }
}

.rating--thin img {
    width: auto;
    height: 1.125rem;

    @media only screen and (min-width: 768px) {
        height: 1.25rem;
    }
}
