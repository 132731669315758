.focal-card {
    width: 100%;
    max-width: 26rem;
    margin-left: auto;
    margin-right: auto;
    border: 0.0625rem solid var(--bs-gray-100);
    border-radius: 0.25rem;

    .card-footer {
        text-align: center;
    }
}
