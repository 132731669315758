.prize-table {
    width: 100%;
    max-width: 29.375rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    border: 0.0625rem solid var(--bs-gray-100);

    @media only screen and (min-width: 480px) {
        padding: 1.5rem 2rem;
        margin-bottom: 2rem;
    }

    [class*='col'] {
        flex: 0 0 50%;
        max-width: 50%;

        @media only screen and (min-width: 768px) {
            flex: 0 0 55%;
            max-width: 55%;
            padding-left: 0;
            padding-right: 0;
        }
    }

    [class*='col'] + [class*='col'] {
        @media only screen and (min-width: 768px) {
            flex: 0 0 45%;
            max-width: 45%;
        }
    }

    .table thead th {
        border-bottom: 0.0625rem solid var(--bs-gray-100);
    }

    th,
    td {
        padding-left: 0;
        padding-right: 0;
        border-top: none;
        border-bottom: 0.0625rem solid var(--bs-gray-100);
    }

    td:first-child {
        width: 40%;
        font-weight: 700;
        color: inherit;

        @media only screen and (min-width: 480px) {
            width: 50%;
        }
    }

    td:last-child {
        width: 60%;
        font-weight: 400;
        color: var(--bs-red-light);

        @media only screen and (min-width: 480px) {
            width: 50%;
        }
    }

    td.complete:last-child {
        color: var(--bs-green);
    }
}

.prize-table__title {
    margin-bottom: 1rem;
    font-family: var(--bs-body-font-family);
    font-size: 1.125rem;
    font-weight: 700;
    color: inherit;
}
