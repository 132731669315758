.testimonial {
    padding: 1rem;
    border: 0.25rem solid var(--bs-purple);
    color: inherit;
    margin-bottom: 1rem;
    border-radius: 1rem;

    :last-child {
        margin-bottom: 0;
    }
}

.testimonial__quote,
.testimonial__author {
    font-size: 1rem;

    @media only screen and (min-width: 768px) {
        font-size: 1.125rem;
    }
}

.testimonial__quote {
    padding: 0;
    border-left: none;
    margin-bottom: 1rem;

    p:last-child {
        margin-bottom: 0;
    }
}

.testimonial__author {
    color: var(--bs-green-neon);
    font-weight: 700;
}
