.social-link {
    background-color: rgba(var(--bs-black-rgb), 0.0625);
    padding: 1rem;
    border-radius: 0.5rem;

    > a {
        color: inherit;
        text-decoration: none;
    }
}
