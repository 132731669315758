/* Remove Google Recaptcha badge */
.grecaptcha-badge {
    display: none !important;
}

/* Force overflow hidden */
.overflow-hidden {
    overflow: hidden !important;
}

/* Remove Bootstrap collapsing animation */
.collapsing.no-collapsing {
    height: auto !important;
    transition: none !important;
}

/* Autocomplete UI menu (For FAQs page) */
.ui-menu {
    padding: 0;
    margin: 0;
    border-left: 0.0625rem solid var(--bs-gray-100);
    border-right: 0.0625rem solid var(--bs-gray-100);
    border-bottom: 0.0625rem solid var(--bs-gray-100);
    background-color: var(--bs-white);
    list-style: none;
}

.ui-menu-item {
    padding: 1rem;
    border-bottom: 0.0625rem solid var(--bs-gray-100);
    background-color: var(--bs-white);
    cursor: pointer;

    &:last-of-type {
        border-bottom: none;
    }

    &:hover {
        background-color: var(--bs-gray-100);
    }
}

/* Fix for floated image */
p:has(> img.float-md-start),
p:has(> img.float-md-end) {
    margin-bottom: 0;

    & + h3 {
        margin-top: 0;
    }

    &::before {
        display: block;
        clear: both;
        content: '';
    }
}

/* Mimic original BP/BK text style for certain content sections */
.content-special {
    h2 {
        margin-bottom: 1.5rem;
    }

    p {
        font-weight: 300;
        margin-bottom: 1.5rem;
    }
}
