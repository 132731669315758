.jumbotron {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding-top: 2.5rem;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-body-image-alt);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 1200px) {
        aspect-ratio: 7 / 3;
        padding-top: 0;
    }
}

.jumbotron__title {
    color: var(--bs-white);
    margin-bottom: 0.5rem;
    font-size: 3rem;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (min-width: 400px) {
        font-size: 3.5rem;
    }

    @media screen and (min-width: 1200px) {
        font-size: 3.25rem;
    }

    b {
        display: block;
        color: var(--bs-green-neon);
        font-size: 3rem;
        font-weight: inherit;

        @media screen and (min-width: 400px) {
            font-size: 3.5rem;
        }

        @media screen and (min-width: 1200px) {
            font-size: 4.5rem;
        }
    }
}

.jumbotron__subtext,
.jumbotron__offer__text {
    color: var(--bs-white);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;

    @media screen and (min-width: 1200px) {
        font-size: 1.5rem;
    }
}

.jumbotron__subtext {
    margin-bottom: 2rem;
}

.jumbotron__offer {
    position: relative;
    max-width: 20rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: 1200px) {
        max-width: 24rem;
        margin-bottom: 4rem;
        margin-left: 0;
        margin-right: 0;
    }
}

.jumbotron__offer__text {
    min-height: calc(5.5rem);
    padding: 1.5rem 1rem 1.5rem 3.5rem;
    margin-bottom: 0;
    margin-left: 2.5rem;
    background-color: rgba(var(--bs-white-rgb), 0.25);
    text-align: left;
    border-radius: 1rem;
}

.jumbotron__offer__icon {
    position: absolute;
    top: 50%;
    left: 0;
    width: 5rem;
    height: auto;
    transform: translateY(-50%);
}

.jumbotron__picture {
    z-index: 0;
    display: block;
    margin-top: -4rem;
    margin-bottom: 0;
    pointer-events: none;

    @media screen and (min-width: 1200px) {
        position: absolute;
        bottom: 0;
        left: 40%;
        right: 10%;
        margin-top: 0;
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;

        @media screen and (min-width: 1200px) {
            width: 100%;
        }
    }
}
