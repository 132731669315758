.icon {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 1rem;
}

.icon-sm {
    width: 0.75rem;
    height: 0.75rem;
    background-size: auto 0.75rem;
}

.icon-star {
    background-image: url('../../images/icons/star-gold.svg');
}
