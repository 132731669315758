/* Text - Inherit */
.text-inherit {
    color: inherit !important;
}

/* Text - Colors */
.text-highlight {
    color: var(--bs-highlight-color);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(
            in srgb,
            var(--bs-highlight-color),
            var(--bs-highlight-color) 15%
        );
    }
}

.text-black {
    color: var(--bs-black);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-black), var(--bs-black) 15%);
    }
}

.text-white {
    color: var(--bs-white);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-white), var(--bs-black) 15%);
    }
}

.text-gray-100 {
    color: var(--bs-gray-100);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-100), var(--bs-black) 15%);
    }
}

.text-gray-200 {
    color: var(--bs-gray-200);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-200), var(--bs-black) 15%);
    }
}

.text-gray-300 {
    color: var(--bs-gray-300);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-300), var(--bs-black) 15%);
    }
}

.text-gray-400 {
    color: var(--bs-gray-400);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-400), var(--bs-black) 15%);
    }
}

.text-gray-500 {
    color: var(--bs-gray-500);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-500), var(--bs-black) 15%);
    }
}

.text-gray-600 {
    color: var(--bs-gray-600);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-600), var(--bs-black) 15%);
    }
}

.text-gray-700 {
    color: var(--bs-gray-700);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-700), var(--bs-black) 15%);
    }
}

.text-gray-800 {
    color: var(--bs-gray-800);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-800), var(--bs-black) 15%);
    }
}

.text-gray-900 {
    color: var(--bs-gray-900);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gray-900), var(--bs-black) 15%);
    }
}

.text-blue-alt {
    color: var(--bs-blue-alt);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-blue-alt), var(--bs-black) 15%);
    }
}

.text-blue-light {
    color: var(--bs-blue-light);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-blue-light), var(--bs-black) 15%);
    }
}

.text-blue {
    color: var(--bs-blue);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-blue), var(--bs-black) 15%);
    }
}

.text-cyan {
    color: var(--bs-cyan);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-cyan), var(--bs-black) 15%);
    }
}

.text-gold {
    color: var(--bs-gold);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-gold), var(--bs-black) 15%);
    }
}

.text-green-light {
    color: var(--bs-green-light);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-green-light), var(--bs-black) 15%);
    }
}

.text-green-neon {
    color: var(--bs-green-neon);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-green-neon), var(--bs-black) 15%);
    }
}

.text-green {
    color: var(--bs-green);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-green), var(--bs-black) 15%);
    }
}

.text-indigo {
    color: var(--bs-indigo);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-indigo), var(--bs-black) 15%);
    }
}

.text-lemon {
    color: var(--bs-lemon);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-lemon), var(--bs-black) 15%);
    }
}

.text-orange {
    color: var(--bs-orange);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-orange), var(--bs-black) 15%);
    }
}

.text-red-light {
    color: var(--bs-red-light);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-red-light), var(--bs-black) 15%);
    }
}

.text-red {
    color: var(--bs-red);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-red), var(--bs-black) 15%);
    }
}

.text-sky {
    color: var(--bs-sky);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-sky), var(--bs-black) 15%);
    }
}

.text-steel {
    color: var(--bs-steel);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-steel), var(--bs-black) 15%);
    }
}

.text-teal {
    color: var(--bs-teal);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-teal), var(--bs-black) 15%);
    }
}

.text-yellow-pale {
    color: var(--bs-yellow-pale);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-yellow-pale), var(--bs-black) 15%);
    }
}

.text-yellow {
    color: var(--bs-yellow);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-yellow), var(--bs-black) 15%);
    }
}

.text-primary {
    color: var(--bs-primary);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-primary), var(--bs-black) 15%);
    }
}

.text-primary-light {
    color: var(--bs-primary-light);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-primary-light), var(--bs-black) 15%);
    }
}

.text-primary-pale {
    color: var(--bs-primary-pale);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(in srgb, var(--bs-primary-pale), var(--bs-black) 15%);
    }
}

.text-primary-contrast {
    color: var(--bs-primary-contrast);

    &:is(a):hover,
    &:is(a):focus,
    &:is(a):active {
        color: color-mix(
            in srgb,
            var(--bs-primary-contrast),
            var(--bs-black) 15%
        );
    }
}
