/* Font Family - Sans-Serif */
.font-family-sans-serif {
    font-family: var(--bs-font-sans-serif);
}

/* Font Family - Serif */
.font-family-serif {
    font-family: var(--heading-font-family);
}

/* Font Family - Alt */
.font-family-alt {
    font-family: var(--subhead-font-family);
}

/* Font Family - Misc */
.font-hallowheel {
    font-family: 'ADrippingMarker', var(--bs-font-sans-serif);
    font-size: 3.5rem;
    text-transform: uppercase;

    .bg-image & {
        text-shadow: 0 0.25rem 1.5rem var(--bs-black);
    }

    b {
        color: var(--bs-orange);
    }
}

/* Font Weights */
.font-weight-100 {
    font-weight: var(--font-weight-100);
}

.font-weight-200 {
    font-weight: var(--font-weight-200);
}

.font-weight-300 {
    font-weight: var(--font-weight-300);
}

.font-weight-400 {
    font-weight: var(--font-weight-400);
}

.font-weight-500 {
    font-weight: var(--font-weight-500);
}

.font-weight-600 {
    font-weight: var(--font-weight-600);
}

.font-weight-700 {
    font-weight: var(--font-weight-700);
}

.font-weight-800 {
    font-weight: var(--font-weight-800);
}

.font-weight-900 {
    font-weight: var(--font-weight-900);
}
