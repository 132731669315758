.score {
    data {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 4rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0.25rem;
        background-image: url('../../images/graphics/score-ball.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        color: var(--bs-white);
        font-size: 1.375rem;
        font-weight: 700;
        text-transform: uppercase;
    }

    figcaption {
        text-align: center;
    }

    figcaption cite {
        display: block;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
    }

    figcaption a {
        display: block;
        font-size: 0.875rem;
        font-weight: 300;
    }
}
