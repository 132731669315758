/* Facebook themed button */
.btn-facebook {
    position: relative;
    padding-left: 3.875rem;
    border-color: transparent;
    background-color: #1b77f2;
    color: var(--bs-white);

    &,
    &:hover,
    &:focus,
    &:active,
    &:disabled,
    &.disabled {
        border-color: #1b77f2;
        background-color: #1b77f2;
        color: var(--bs-white);
    }

    &.btn-lg img {
        width: 2.375rem;
        height: 2.375rem;
    }

    img {
        position: absolute;
        top: 50%;
        left: 0.75rem;
        display: block;
        width: 2rem;
        height: 2rem;
        transform: translateY(-50%);
    }

    span {
        white-space: normal;
    }
}
