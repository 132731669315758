.site {
    padding-top: 0;
    padding-bottom: 1.5rem;

    @media only screen and (min-width: 768px) {
        padding-bottom: 1rem;
    }

    .account-details & {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-left: calc(-0.5 * var(--spacer-4));
        margin-right: calc(-0.5 * var(--spacer-4));

        @media only screen and (min-width: 768px) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &.site--bordered {
        padding-top: 1rem;
        border-top: 0.0625rem solid var(--bs-gray-300);
    }

    p {
        margin-bottom: 0.75rem;
        line-height: 1.125;
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    .btn {
        font-weight: 500;
    }
}

.site__subtext,
.site__date {
    font-size: 0.9375rem;
}

.site__date {
    color: var(--bs-gray-500);
}
