.event {
}

.event__picture {
    img {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }
}

.event__content {
    padding: 1rem;
    background-color: var(--bs-body-semi-transparent);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
