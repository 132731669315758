/* Pop up for request link */
#popup .slidemeup {
    position: absolute;
    bottom: -250px;
    height: 150px;
    width: 100%;
    left: 0;
    animation-name: slideup;
    animation-delay: 0.5s;
    animation-duration: 0.8s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-out;
}

/* Pop up for terms and conditions */
.terms_popup_1 {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--zindex-fixed);
    background: rgba(0, 0, 0, 0.5);
    float: left;
    display: none;
}

.terms_popup_2 {
    width: 900px;
    height: 520px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -450px;
    background-color: #fff;
    border-radius: 10px;
}

.terms_popup_content {
    width: 860px;
    margin: 10px 20px;
    height: 480px;
    float: left;
    overflow-y: scroll;
}

@keyframes slideup {
    0% {
        bottom: -250px;
    }
    100% {
        bottom: 0;
    }
}
