.dropdown {
    position: static;
}

.dropdown-toggle {
    position: relative;

    &::after {
        width: 0.75rem;
        height: 0.5rem;
        margin-left: 0.25rem;
        border: none;
        background-image: url(../../images/icons/chevron.svg);
        background-repeat: no-repeat;
        background-size: 0.75rem 0.5rem;
        vertical-align: baseline;
    }
}

.dropdown-menu {
    --bs-dropdown-link-color: var(--bs-body-color);
    --bs-dropdown-link-hover-bg: var(--bs-gray-100);
    --bs-dropdown-link-hover-color: var(--bs-body-color);
    --bs-dropdown-link-active-bg: var(--bs-primary);
    --bs-dropdown-link-active-color: var(--bs-white);

    padding: 0;
    border-radius: 0;
    border: none;

    &[data-bs-popper] {
        --bs-dropdown-spacer: 0;
    }

    @media only screen and (min-width: 1200px) {
        padding: 1rem;
        text-align: center;
        box-shadow: 0 0.25rem 0.5rem rgba(var(--bs-black-rgb), 0.25);
    }

    .navbar-nav & {
        @media only screen and (min-width: 1200px) {
            position: absolute;
            left: 1rem;
            right: 1rem;
        }
    }
}

.dropdown-item {
    display: block;
    color: var(--bs-body-color);
    font-size: 0.9375rem;
    font-weight: inherit;
    line-height: inherit;

    @media only screen and (min-width: 1200px) {
        display: inline-block;
        width: auto;
        padding-top: 0.75rem;
        padding-right: 1rem;
        padding-bottom: 0.75rem;
        padding-left: 1rem;
        text-align: center;
    }
}
