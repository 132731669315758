#fos_comment_comment_body {
    border-radius: 3px;
    border: 1px solid #999;
    font-size: 13px;
    color: #666;
    padding: 5px;
    height: 63px;
    min-height: 21px;
    width: 100% !important;
    line-height: 1;
}

.fos_comment_submit {
    text-align: right;
    float: left;
    width: 100%;
}

.fos_comment_submit input {
    font-size: 14px !important;
    padding: 10px !important;
    margin-top: 5px;
    float: right;
}

.comment_avatar {
    float: left;
    margin-right: 10px;
    max-width: 33px;
    max-height: 33px;
}

.comment_name {
    float: left;
    text-decoration: none;
}

.comment_text {
    color: #666;
    float: left;
    width: 915px;
    padding: 0;
    line-height: 1.5;
    margin: 0;
    text-align: left;
    font-size: 12px;
}
