.listing__inner {
    --listing-background: var(--listing-primary-background);
    --listing-color: var(--listing-primary-color);
    --listing-terms: var(--listing-primary-terms);
    --listing-link: var(--listing-primary-link);
    --listing-deposit: var(--listing-primary-deposit);
    --listing-bold: var(--listing-primary-bold);
}

.listings [class*='col']:nth-of-type(2n) .listing--default .listing__inner,
.listings [class*='col']:nth-of-type(2n) .listing--default .listing__inner {
    --listing-background: var(--listing-secondary-background);
    --listing-color: var(--listing-secondary-color);
    --listing-terms: var(--listing-secondary-terms);
    --listing-link: var(--listing-secondary-link);
    --listing-deposit: var(--listing-secondary-deposit);
    --listing-bold: var(--listing-secondary-bold);
}

.listing--light .listing__inner {
    --listing-background: url('../../images/backgrounds/listing-light-s.webp')
        top center/100% 100%;
    --listing-color: var(--bs-black);
    --listing-terms: var(--bs-red-light);
    --listing-link: var(--bs-primary);
    --listing-deposit: var(--bs-gray-600);
    --listing-bold: url('../../images/icons/deposit-white.svg');
}

.listing--token .listing__inner {
    --listing-background: rgba(255, 255, 255, 0.875);
    --listing-color: var(--bs-black);
    --listing-terms: var(--bs-red-light);
    --listing-link: var(--bs-primary);
    --listing-deposit: var(--bs-gray-600);
    --listing-bold: url('../../images/icons/deposit-white.svg');
}

@media only screen and (min-width: 768px) {
    .listing--light .listing__inner {
        --listing-background: url('../../images/backgrounds/listing-light.webp')
            top center/100% 100%;
        --listing-color: var(--bs-black);
        --listing-terms: var(--bs-red-light);
        --listing-link: var(--bs-primary);
        --listing-deposit: var(--bs-gray-600);
        --listing-bold: url('../../images/icons/deposit-white.svg');
    }
    .listing--token .listing__inner {
        --listing-background: rgba(255, 255, 255, 0.875);
        --listing-color: var(--bs-black);
        --listing-terms: var(--bs-red-light);
        --listing-link: var(--bs-primary);
        --listing-deposit: var(--bs-gray-600);
        --listing-bold: url('../../images/icons/deposit-white.svg');
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .listings--full
        [class*='col']:nth-of-type(4n)
        .listing--default
        .listing__inner,
    .listings--full
        [class*='col']:nth-of-type(4n-1)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-primary-background);
        --listing-color: var(--listing-primary-color);
        --listing-terms: var(--listing-primary-terms);
        --listing-link: var(--listing-primary-link);
        --listing-deposit: var(--listing-primary-deposit);
        --listing-bold: var(--listing-primary-bold);
    }

    .listings--full
        [class*='col']:nth-of-type(4n-2)
        .listing--default
        .listing__inner,
    .listings--full
        [class*='col']:nth-of-type(4n-3)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-secondary-background);
        --listing-color: var(--listing-secondary-color);
        --listing-terms: var(--listing-secondary-terms);
        --listing-link: var(--listing-secondary-link);
        --listing-deposit: var(--listing-secondary-deposit);
        --listing-bold: var(--listing-secondary-bold);
    }
}

@media only screen and (min-width: 1200px) {
    .listings [class*='col']:nth-of-type(4n) .listing--default .listing__inner,
    .listings
        [class*='col']:nth-of-type(4n-3)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-primary-background);
        --listing-color: var(--listing-primary-color);
        --listing-terms: var(--listing-primary-terms);
        --listing-link: var(--listing-primary-link);
        --listing-deposit: var(--listing-primary-deposit);
        --listing-bold: var(--listing-primary-bold);
    }

    .listings
        [class*='col']:nth-of-type(4n-1)
        .listing--default
        .listing__inner,
    .listings
        [class*='col']:nth-of-type(4n-2)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-secondary-background);
        --listing-color: var(--listing-secondary-color);
        --listing-terms: var(--listing-secondary-terms);
        --listing-link: var(--listing-secondary-link);
        --listing-deposit: var(--listing-secondary-deposit);
        --listing-bold: var(--listing-secondary-bold);
    }

    .listings
        .col-lg-12
        ~ [class*='col']:nth-of-type(4n-2)
        .listing--default
        .listing__inner,
    .listings
        .col-lg-12
        ~ [class*='col']:nth-of-type(4n-3)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-primary-background);
        --listing-color: var(--listing-primary-color);
        --listing-terms: var(--listing-primary-terms);
        --listing-link: var(--listing-primary-link);
        --listing-deposit: var(--listing-primary-deposit);
        --listing-bold: var(--listing-primary-bold);
    }

    .listings
        .col-lg-12
        ~ [class*='col']:nth-of-type(4n)
        .listing--default
        .listing__inner,
    .listings
        .col-lg-12
        ~ [class*='col']:nth-of-type(4n-1)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-secondary-background);
        --listing-color: var(--listing-secondary-color);
        --listing-terms: var(--listing-secondary-terms);
        --listing-link: var(--listing-secondary-link);
        --listing-deposit: var(--listing-secondary-deposit);
        --listing-bold: var(--listing-secondary-bold);
    }

    .listings--full
        [class*='col']:nth-of-type(2n-1)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-primary-background);
        --listing-color: var(--listing-primary-color);
        --listing-terms: var(--listing-primary-terms);
        --listing-link: var(--listing-primary-link);
        --listing-deposit: var(--listing-primary-deposit);
        --listing-bold: var(--listing-primary-bold);
    }

    .listings--full
        [class*='col']:nth-of-type(2n)
        .listing--default
        .listing__inner {
        --listing-background: var(--listing-secondary-background);
        --listing-color: var(--listing-secondary-color);
        --listing-terms: var(--listing-secondary-terms);
        --listing-link: var(--listing-secondary-link);
        --listing-deposit: var(--listing-secondary-deposit);
        --listing-bold: var(--listing-secondary-bold);
    }
}
