button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    &,
    &:hover,
    &:focus,
    &:active {
        outline: none;
        box-shadow: none !important;
    }

    &:disabled {
        cursor: not-allowed;
    }
}
