.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-fixed);
    width: 100%;
    display: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: var(--bs-primary);
    color: var(--bs-primary-contrast);
    text-align: center;
    border-radius: 0;
    opacity: 0;
    box-shadow: var(--box-shadow-inverse);
    transition: opacity 400ms;

    &.show {
        display: block;
        opacity: 1;
    }

    p {
        color: var(--bs-primary-contrast) !important;
    }

    a {
        color: var(--bs-primary-contrast) !important;
        text-decoration: underline;
        white-space: nowrap;
    }

    .btn {
        background-color: var(--bs-white) !important;
        color: var(--bs-primary) !important;
    }
}
