.brand-cta {
    padding: 1.5rem 0 1.5rem 0.25rem;
    background-image: url('../../images/backgrounds/brand-cta-s.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--bs-white);
    transform-origin: top center;
    transform: translateY(100%);
    transition: transform var(--transition-base-duration)
        var(--transition-base-timing);

    @media only screen and (min-width: 394px) {
        background-image: url('../../images/backgrounds/brand-cta-m.webp');
    }

    @media only screen and (min-width: 835px) {
        background-image: url('../../images/backgrounds/brand-cta-l.webp');
    }

    &.show {
        transform: translateY(0);
    }

    h2 {
        margin: 0;
        font-size: 1.375rem;
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
    }

    img {
        max-height: 3.125rem;
    }

    a,
    button,
    .btn-xxl,
    .btn-xxl-consistent {
        padding: 0.25rem 0.375rem;
        margin: 0;
        font-size: 1.5rem;
    }
}
