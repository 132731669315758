.notification {
    padding: 0.75rem 1rem;
    border-bottom: 0.0625rem solid var(--bs-gray-100);
}

.notification__text {
    margin-bottom: 0;
    line-height: 1.125;

    .notification--unread & {
        font-weight: 500;
    }
}

.notification__time {
    margin-top: 0.25rem;
    margin-bottom: 0;
    font-size: 0.8125rem;
    color: var(--bs-gray-500);
}

.notification__menu {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    opacity: 0;
    transition: opacity var(--transition-base-duration)
        var(--transition-base-timing);
    transform: translateY(-50%);

    &,
    &.collapsing,
    &.show {
        height: auto !important;
    }

    &.show {
        opacity: 1;
    }

    button {
        display: block;
        width: 15rem;
        padding: 0.75rem 1rem;
        background-color: var(--bs-white);
        color: var(--bs-blue);
        font-size: 1.0625rem;
        line-height: 1.125rem;
        border-radius: 0.75rem;
        box-shadow: var(--box-shadow-light);
    }
}

.notification__menu-toggle {
    padding: 0;
    color: var(--bs-gray-400);
    font-size: 0.9375rem;
    cursor: pointer;

    &::after {
        content: '•••';
    }
}
