.reward {
    position: relative;
    padding: 1rem;
    margin-bottom: 1rem;
    background-image: var(--reward-background);
    background-size: cover;
    color: var(--reward-color);

    @media only screen and (max-width: 767px) {
        margin-left: calc(-0.5 * var(--spacer-4));
        margin-right: calc(-0.5 * var(--spacer-4));
    }

    .rewards & {
        @media only screen and (min-width: 768px) and (max-width: 1199px) {
            margin-bottom: 1rem;
        }
    }

    &.reward--success {
        background: rgb(51, 172, 141) !important;
        background-image: linear-gradient(
            240deg,
            rgba(51, 172, 141, 1) 0%,
            rgba(139, 241, 171, 1) 50%,
            rgba(139, 241, 171, 1) 100%
        ) !important;
        background-origin: border-box;
        background-repeat: no-repeat;
    }

    h3 {
        color: inherit;
        font-size: 1.5rem;
        font-weight: 700;
    }
}

.reward__link {
    display: block;
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        color: inherit;
        text-decoration: none;
    }
}

.reward__card,
.reward__image,
.reward__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.reward__card {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
}

.reward__image {
    width: auto;
    height: 6rem;
    object-fit: contain;
}

.reward__logo {
    width: 100%;
    max-width: 80%;
    height: auto;
}

.reward__price {
    color: var(--reward-price);
    font-weight: 500;

    b {
        color: var(--reward-color);
        font-weight: 700;
    }
}
