.message-inbox {
    font-size: 0.8em;
}

.message-inbox th,
.message-inbox td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
}

.message-inbox .unread {
    background-color: var(--bs-white);
    font-weight: bold;
    cursor: pointer;
}

.message-inbox .read {
    background-color: var(--bs-white);
}

.message-inbox .unread .subject {
    font-weight: bold;
}

.message-inbox .unread .title {
    font-weight: bold;
}

.message-inbox .read .subject {
    font-weight: normal;
}

.message-inbox .read .title {
    font-weight: normal;
}

.message-inbox .title time {
    float: right;
    padding-top: 0.3em;
    font-size: 0.6em;
}

.message-inbox .message-body p {
    padding: 0 0 0.2rem 0;
    margin: 0;
    font-weight: normal;
}

.message-inbox .unread .message-body p.message-main {
    margin: 0;
    font-weight: bold;
}

.message-inbox .unread .message-body p.message-minor {
    font-weight: normal !important;
}
