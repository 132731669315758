/* Background - Image */
.bg-image {
    background-color: var(--bs-body-bg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image-fixed {
    background-attachment: fixed;
}

.bg-image-top {
    background-position: top center;
}

.bg-image-bottom {
    background-position: bottom center;
}

/* Background - Image */
.bg-body-image {
    background-attachment: fixed;
    background-color: var(--bs-body-bg);
    background-image: var(--bs-body-image);
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* Background - Transparent */
.bg-semi-transparent {
    background-color: var(--bs-body-semi-transparent);
}

/* Background - Colors */
.bg-black {
    background-color: var(--bs-black);
}

.bg-black-rgba {
    background-color: var(--bs-black-rgba);
}

.bg-white {
    background-color: var(--bs-white);
}

.bg-white-rgba {
    background-color: var(--bs-white-rgba);
}

.bg-gray-100 {
    background-color: var(--bs-gray-100);
}

.bg-gray-200 {
    background-color: var(--bs-gray-200);
}

.bg-gray-300 {
    background-color: var(--bs-gray-300);
}

.bg-gray-400 {
    background-color: var(--bs-gray-400);
}

.bg-gray-500 {
    background-color: var(--bs-gray-500);
}

.bg-gray-600 {
    background-color: var(--bs-gray-600);
}

.bg-gray-700 {
    background-color: var(--bs-gray-700);
}

.bg-gray-800 {
    background-color: var(--bs-gray-800);
}

.bg-gray-900 {
    background-color: var(--bs-gray-900);
}

.bg-blue-alt {
    background-color: var(--bs-blue-alt);
}

.bg-blue-light {
    background-color: var(--bs-blue-light);
}

.bg-blue {
    background-color: var(--bs-blue);
}

.bg-cyan {
    background-color: var(--bs-cyan);
}

.bg-gold {
    background-color: var(--bs-gold);
}

.bg-green-light {
    background-color: var(--bs-green-light);
}

.bg-green-neon {
    background-color: var(--bs-green-neon);
}

.bg-green {
    background-color: var(--bs-green);
}

.bg-indigo {
    background-color: var(--bs-indigo);
}

.bg-lemon {
    background-color: var(--bs-lemon);
}

.bg-orange {
    background-color: var(--bs-orange);
}

.bg-pink {
    background-color: var(--bs-pink);
}

.bg-purple-dark {
    background-color: var(--bs-purple-dark);
}

.bg-purple {
    background-color: var(--bs-purple);
}

.bg-red-light {
    background-color: var(--bs-red-light);
}

.bg-red {
    background-color: var(--bs-red);
}

.bg-sky {
    background-color: var(--bs-sky);
}

.bg-steel {
    background-color: var(--bs-steel);
}

.bg-teal {
    background-color: var(--bs-teal);
}

.bg-yellow-pale {
    background-color: var(--bs-yellow-pale);
}

.bg-yellow {
    background-color: var(--bs-yellow);
}

.bg-primary {
    background-color: var(--bs-primary);
}

.bg-primary-light {
    background-color: var(--bs-primary-light);
}

.bg-primary-pale {
    background-color: var(--bs-primary-pale);
}

.bg-primary-contrast {
    background-color: var(--bs-primary-contrast);
}

.bg-primary-gradient {
    background-image: var(--bs-gradient-primary);
}

.bg-secondary-gradient {
    background-image: var(--bs-gradient-secondary);
}
