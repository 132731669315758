.filter-menu {
    display: block;

    @media only screen and (max-width: 767px) {
        .container:has(&) {
            position: relative;
        }

        [class*='col']:has(> &) {
            position: sticky;
            top: 68px;
            left: 0;
            right: 0;
            margin-top: -1rem;
            z-index: var(--zindex-fixed);
            background-color: var(--bs-body-bg);
            color: var(--bs-body-color);
        }
    }

    @media only screen and (min-width: 768px) {
        position: sticky;
        top: 0;
    }
}

.filter-menu__overflow {
    position: relative;

    @media only screen and (max-width: 767px) {
        overflow: auto;
        white-space: nowrap;
    }
}

.filter-menu__expanded {
    @media only screen and (max-width: 767px) {
        position: fixed;
        top: 0 !important;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: var(--zindex-fixed);
        padding: 0;
        border: 0;
        background: rgba(0, 0, 0, 0.125);
        color: var(--bs-white);
    }

    @media only screen and (min-width: 768px) {
        display: block !important;
    }
}

.filter-menu__filters {
    > .container:not(:last-child) {
        border-bottom: 0.0625rem solid var(--bs-gray-100);
    }

    @media only screen and (max-width: 767px) {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        z-index: var(--zindex-fixed);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: var(--bs-body-bg);
        color: var(--bs-body-color);
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;

        > .container {
            flex: 0 0 auto;
            padding-top: 1rem;
            padding-bottom: 1rem;
        }

        > .container:not(:first-child):not(:last-child) {
            position: relative;
            flex: 1 1 auto;
            overflow: auto;
        }
    }

    fieldset {
        margin-bottom: 0;
    }

    legend {
        position: relative;
        float: left;
        padding: 0.5rem 0;
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1rem;
        pointer-events: all;
        cursor: pointer;
    }

    legend::after {
        position: absolute;
        top: 50%;
        right: 0;
        width: 1rem;
        height: 0.58rem;
        background-image: url(../../images/icons/chevron.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 1rem 0.58rem;
        transform: translateY(-50%);
        transform-origin: center;
        content: '';
    }

    legend[aria-expanded='true']::after {
        transform: translateY(-50%) rotate(180deg);
    }
}

.filter-menu__active {
    padding: 0 0 1rem;
    margin: 0;

    .list-inline-item:not(:first-child) {
        margin-left: 0.125rem;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.125rem;
    }

    @media only screen and (min-width: 768px) {
        .list-inline-item {
            width: 100%;
            margin-bottom: 0.5rem;
        }
    }
}

.filter-menu__group {
    margin-top: 0;
    margin-bottom: 0;

    .list-inline-item {
        margin-bottom: 0.5rem;
    }

    .list-inline-item:not(:first-child) {
        margin-left: 0.125rem;
    }

    .list-inline-item:not(:last-child) {
        margin-right: 0.125rem;
    }
}

.filter-menu__check-tablet {
    &.form-button {
        @media only screen and (min-width: 768px) {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    &.form-button .form-button-input {
        @media only screen and (min-width: 768px) {
            position: static;
            top: auto;
            left: auto;
            display: block;
            margin-right: 0.5rem;
        }
    }

    &.form-button .form-button-label,
    &.form-button .form-button-input:checked ~ .form-button-label,
    &.form-button .form-button-input:disabled ~ .form-button-label {
        @media only screen and (min-width: 768px) {
            padding: 0;
            background-color: transparent;
            border-color: transparent;
            color: var(--bs-body-color);
            font-size: 0.875rem;
            margin-bottom: 0;
            border-radius: 0;
            cursor: pointer;
            text-transform: none;
        }
    }
}

.filter-menu__no-check-tablet {
    &.form-button .form-button-label {
        @media only screen and (min-width: 768px) {
            background-color: transparent;
            border-color: transparent;
            color: var(--bs-body-color);
            text-transform: none;
        }
    }

    &.form-button .form-button-input:checked ~ .form-button-label {
        @media only screen and (min-width: 768px) {
            background: var(--bs-primary-pale);
            border-color: transparent;
            color: var(--bs-primary);
        }
    }
}
