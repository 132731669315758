a,
.link {
    display: inline;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    color: var(--bs-link-color);
    font-size: inherit;
    font-weight: inherit;
    text-decoration: var(--bs-link-decoration);
}

.link-absolute {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    article:has(> &),
    div:has(> &) {
        position: relative;
    }
}
