.table {
    --bs-table-color: var(--bs-body-color);
    --bs-table-bg: transparent;
}

.table-responsive {
    .table-bordered {
        border: 1px solid #dee2e6;
    }
}
