select[size] {
    option,
    &:focus option {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;

        &:checked {
            background-color: var(--bs-primary-light) !important;
            background-color: var(--bs-primary-light)
                linear-gradient(
                    0deg,
                    var(--bs-primary-light) 0%,
                    var(--bs-primary-light) 100%
                ) !important;
        }
    }
}
