.instruction {
    h2 {
        margin-bottom: 1.5rem;
        font-family: var(--bs-body-font-family);
        font-size: 1.1875rem;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
    }

    p,
    ul,
    ol {
        font-size: 1rem;
    }

    ul,
    ol {
        padding-left: 1.25rem;
    }

    ol li {
        margin-bottom: 1rem;
    }

    card-body {
        position: relative;
        padding-top: 3.125rem;
        padding-bottom: calc(3.25rem + 1.875rem);
    }
}

.instruction__image,
.instruction__video {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
}

.instruction__skip {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 0.9375rem;
    text-transform: uppercase;
}

@keyframes scale-up-center {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
