.icon-hero {
    display: inline-block;
    width: 100px;
    height: 83px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: '';

    &.center {
        text-align: center;
    }

    h3 {
        display: inline;
        vertical-align: top;
        margin-left: 5rem;
    }
}

.icon-hero-bingo {
    background-image: url('../../images/icons/bingo-lg.svg');
}

.icon-hero-casino {
    background-image: url('../../images/icons/casino-lg.png');
}

.icon-hero-join {
    background-image: url('../../images/icons/join-lg.svg');
}

.icon-hero-rewards {
    background-image: url('../../images/icons/rewards-lg.svg');
}
