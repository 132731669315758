.footer-copyright {
    padding-top: 1rem;
    padding-bottom: 1rem;

    p {
        font-family: var(--subhead-font-family);
        font-size: 0.875rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.footer-copyright__logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;

    @media only screen and (min-width: 768px) {
        margin-left: 1rem;
        margin-right: 1rem;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.footer-copyright__gamstop {
    max-width: 8rem;
}

.footer-copyright__gamcare {
    max-width: 8.4375rem;
}

.footer-copyright__gambleaware {
    max-width: 10rem;
}

.footer-copyright__age {
    max-width: 3.375rem;
}
