.menu {
    ul {
        list-style: none;
        padding-left: 0;
    }

    li {
        margin-bottom: 0;
        border-bottom: 0.0625rem solid var(--bs-gray-100);

        &:last-child {
            border-bottom: 0;
        }
    }

    h3,
    a:not(.menu__link),
    button {
        display: block;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
        color: inherit;
        text-decoration: none;
    }

    a[href='#']:not(.menu__link),
    button:disabled {
        pointer-events: none;
        color: var(--bs-gray-300);
    }
}

.menu__link {
    position: relative;
    display: block;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 2rem;
    color: inherit;
    text-decoration: none;

    &::after {
        position: absolute;
        top: 50%;
        right: 1rem;
        font-family: 'FontAwesome';
        font-size: 1.25rem;
        color: var(--bs-link-color);
        transform: translateY(-50%);
        content: '\f105';
    }

    &:hover {
        background-color: var(--bs-body-semi-transparent);
        text-decoration: none;
    }

    &:hover::after,
    &:hover h2 {
        color: var(--bs-link-hover-color);
    }

    &:hover p {
        color: var(--bs-body-color);
    }

    h2 {
        font-size: 1rem;
        color: var(--bs-link-color);
        margin-bottom: 0;

        &:has(+ p) {
            margin-bottom: 1rem;
        }
    }

    p {
        color: inherit;
        margin-bottom: 0;
    }
}
