.px-6 {
    padding-left: var(--spacer-6) !important;
    padding-right: var(--spacer-6) !important;
}

.py-6 {
    padding-top: var(--spacer-6) !important;
    padding-bottom: var(--spacer-6) !important;
}

.ps-6 {
    padding-left: var(--spacer-6) !important;
}

.pe-6 {
    padding-right: var(--spacer-6) !important;
}

.pt-6 {
    padding-top: var(--spacer-6) !important;
}

.pb-6 {
    padding-bottom: var(--spacer-6) !important;
}

.mx-6 {
    margin-left: var(--spacer-6) !important;
    margin-right: var(--spacer-6) !important;
}

.my-6 {
    margin-top: var(--spacer-6) !important;
    margin-bottom: var(--spacer-6) !important;
}

.ms-6 {
    margin-left: var(--spacer-6) !important;
}

.me-6 {
    margin-right: var(--spacer-6) !important;
}

.mt-6 {
    margin-top: var(--spacer-6) !important;
}

.mb-6 {
    margin-bottom: var(--spacer-6) !important;
}
