.listing-sidebar {
    padding: 0.5rem 0.5rem 1rem;

    .btn-xxl {
        font-size: 1.25rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
    }
}

.listing-sidebar__logo {
    border-radius: 0.5rem;
}

.listing-sidebar__title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: var(--bs-gray-600);
    font-weight: 700;
    text-align: center;

    [data-bs-theme='dark'] & {
        color: var(--bs-white);
    }
}

.listing-sidebar__subtitle {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--bs-red-light);
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
}
