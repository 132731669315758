.section-card {
    position: relative;
    border: none;
    border-radius: 0.5rem;
    margin-bottom: 2rem;
    background-color: transparent;

    &.bordered {
        border: 0.25rem solid var(--bs-white);
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    > :first-child {
        &,
        > img {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
    }

    > :last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .card-body {
        background-color: var(--bs-gray-800);
        background-color: rgba(var(--bs-gray-800-rgb), 0.75);
        color: var(--bs-white);
    }

    .card-body h2 {
        font-size: 1.125rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 1rem;

        @media only screen and (min-width: 768px) {
            font-size: 1.25rem;
        }
    }

    .card-body h3 {
        color: var(--bs-yellow);
        font-size: 1.0625rem;
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0.25rem;
    }

    .card-body p {
        font-size: 0.9375rem;
        font-weight: 500;
    }

    .card-body p:last-of-type {
        margin-bottom: 0;
    }

    .card-body a {
        color: var(--bs-yellow);
    }
}
