.accordion {
    position: relative;
}

.accordion__header {
    position: relative;

    a {
        display: block;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        color: inherit;
        text-decoration: inherit;
    }

    h2,
    h3 {
        margin: 0;
        color: inherit;
    }

    &:has(+ .collapse.show),
    &:has(+ .collapsing) {
        .accordion__icon {
            &::before {
                content: '\f077';
            }

            &::after {
                content: 'Hide';
            }
        }
    }
}

.accordion__icon {
    position: relative;
    padding-left: 2rem;
    color: var(--bs-blue);

    &::before {
        position: absolute;
        top: 50%;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.25rem;
        height: 1.25rem;
        border: 0.125rem solid;
        border-color: inherit;
        color: inherit;
        content: '\f078';
        font-family: 'FontAwesome';
        font-size: 0.625rem;
        border-radius: 50%;
        transform: translateY(-50%);
    }

    &::after {
        content: 'Show';
    }
}

.accordion__content {
    padding-bottom: 1.5rem;
}
