.form-switch {
    position: relative;
    display: block;
    padding-left: 0;

    input {
        display: none;
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + label {
        background-color: var(--bs-primary);
    }

    input:checked + label::before {
        transform: translateX(2rem) translateY(-50%);
    }

    input:checked + label .off {
        display: none;
        opacity: 0;
    }

    input:checked + label .on {
        display: block;
        opacity: 1;
    }

    label {
        position: relative;
        display: block;
        width: 3.75rem;
        height: 1.875rem;
        overflow: hidden;
        background-color: var(--bs-gray-100);
        border: none;
        border-radius: 1rem;
        cursor: pointer;
        transition: background-color 0.3125s ease;
    }

    label::before {
        position: absolute;
        top: 50%;
        left: 0;
        height: 1.5rem;
        width: 1.5rem;
        background-color: var(--bs-white);
        border-radius: 50%;
        content: '';
        transform: translateX(0.3125rem) translateY(-50%);
        transition: transform 0.3125s ease;
    }

    label span {
        position: absolute;
        top: 50%;
        display: block;
        font-size: 0.8125rem;
        font-weight: 700;
        text-transform: uppercase;
        transform: translateY(-50%);
        transition: opacity 0.3125s ease;
    }

    label .on {
        left: 0.5rem;
        display: none;
        opacity: 0;
        color: var(--bs-white);
    }

    label .off {
        left: 2rem;
        opacity: 1;
        color: var(--bs-gray-500);
    }
}
