/**
 * Variables
 *
 * Variables should follow the `var(--component-state-property-size)` formula for
 * consistent naming. Ex: var(--nav-link-disabled-color) and var(--modal-content-box-shadow-xs).
 */
:root {
    /**
     * Color system
     */

    /* Gray RGB values */
    --bs-black-rgb: 0, 0, 0;
    --bs-white-rgb: 255, 255, 255;
    --bs-gray-100-rgb: 235, 235, 235;
    --bs-gray-200-rgb: 209, 209, 209;
    --bs-gray-300-rgb: 180, 180, 180;
    --bs-gray-400-rgb: 146, 146, 146;
    --bs-gray-500-rgb: 128, 128, 128;
    --bs-gray-600-rgb: 94, 94, 94;
    --bs-gray-700-rgb: 64, 64, 64;
    --bs-gray-800-rgb: 51, 51, 51;
    --bs-gray-900-rgb: 33, 33, 33;

    /* Gray color variables */
    --bs-black: rgb(var(--bs-black-rgb));
    --bs-white: rgb(var(--bs-white-rgb));
    --bs-gray-100: rgb(var(--bs-gray-100-rgb));
    --bs-gray-200: rgb(var(--bs-gray-200-rgb));
    --bs-gray-300: rgb(var(--bs-gray-300-rgb));
    --bs-gray-400: rgb(var(--bs-gray-400-rgb));
    --bs-gray-500: rgb(var(--bs-gray-500-rgb));
    --bs-gray-600: rgb(var(--bs-gray-600-rgb));
    --bs-gray-700: rgb(var(--bs-gray-700-rgb));
    --bs-gray-800: rgb(var(--bs-gray-800-rgb));
    --bs-gray-900: rgb(var(--bs-gray-900-rgb));

    /* Base RGB values */
    --bs-blue-alt-rgb: 57, 117, 234;
    --bs-blue-light-rgb: 130, 202, 255;
    --bs-blue-rgb: 11, 153, 255;
    --bs-cyan-rgb: 34, 167, 211;
    --bs-gold-rgb: 255, 190, 35;
    --bs-green-light-rgb: 178, 255, 89;
    --bs-green-neon-rgb: 224, 255, 2;
    --bs-green-rgb: 54, 183, 0;
    --bs-indigo-rgb: 102, 16, 242;
    --bs-lemon-rgb: 246, 251, 8;
    --bs-orange-rgb: 241, 90, 35;
    --bs-pink-rgb: 214, 51, 132;
    --bs-purple-dark-rgb: 33, 0, 106;
    --bs-purple-rgb: 111, 66, 193;
    --bs-red-light-rgb: 233, 35, 32;
    --bs-red-rgb: 166, 23, 23;
    --bs-sky-rgb: 162, 219, 254;
    --bs-steel-rgb: 92, 110, 131;
    --bs-teal-rgb: 32, 201, 151;
    --bs-yellow-pale-rgb: 253, 244, 169;
    --bs-yellow-rgb: 255, 200, 0;

    /* Base color variables */
    --bs-blue-alt: rgb(var(--bs-blue-alt-rgb));
    --bs-blue-light: rgb(var(--bs-blue-light-rgb));
    --bs-blue: rgb(var(--bs-blue-rgb));
    --bs-cyan: rgb(var(--bs-cyan-rgb));
    --bs-gold: rgb(var(--bs-gold-rgb));
    --bs-green-light: rgb(var(--bs-green-light-rgb));
    --bs-green-neon: rgb(var(--bs-green-neon-rgb));
    --bs-green: rgb(var(--bs-green-rgb));
    --bs-indigo: rgb(var(--bs-indigo-rgb));
    --bs-lemon: rgb(var(--bs-lemon-rgb));
    --bs-orange: rgb(var(--bs-orange-rgb));
    --bs-pink: rgb(var(--bs-pink-rgb));
    --bs-purple-dark: rgb(var(--bs-purple-dark-rgb));
    --bs-purple: rgb(var(--bs-purple-rgb));
    --bs-red-light: rgb(var(--bs-red-light-rgb));
    --bs-red: rgb(var(--bs-red-rgb));
    --bs-sky: rgb(var(--bs-sky-rgb));
    --bs-steel: rgb(var(--bs-steel-rgb));
    --bs-teal: rgb(var(--bs-teal-rgb));
    --bs-yellow-pale: rgb(var(--bs-yellow-pale-rgb));
    --bs-yellow: rgb(var(--bs-yellow-rgb));

    /* Theme RGB values */
    --bs-primary-rgb: 13, 110, 253;
    --bs-primary-light-rgb: 110, 168, 253;
    --bs-primary-pale-rgb: 208, 226, 254;
    --bs-primary-contrast-rgb: var(--bs-white-rgb);
    --bs-secondary-rgb: var(--bs-gray-500-rgb);
    --bs-secondary-light-rgb: var(--bs-gray-400-rgb);
    --bs-secondary-pale-rgb: var(--bs-gray-100-rgb);
    --bs-secondary-contrast-rgb: var(--bs-white-rgb);
    --bs-success-rgb: var(--bs-green-rgb);
    --bs-info-rgb: var(--bs-cyan-rgb);
    --bs-warning-rgb: var(--bs-gold-rgb);
    --bs-danger-rgb: var(--bs-red-rgb);

    /* Theme color variables */
    --bs-primary: rgb(var(--bs-primary-rgb));
    --bs-primary-light: rgb(var(--bs-primary-light-rgb));
    --bs-primary-pale: rgb(var(--bs-primary-pale-rgb));
    --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));
    --bs-secondary: rgb(var(--bs-secondary-rgb));
    --bs-secondary-light: rgb(var(--bs-secondary-light-rgb));
    --bs-secondary-pale: rgb(var(--bs-secondary-pale-rgb));
    --bs-secondary-contrast: rgb(var(--bs-secondary-contrast-rgb));
    --bs-success: rgb(var(--bs-success-rgb));
    --bs-info: rgb(var(--bs-info-rgb));
    --bs-warning: rgb(var(--bs-warning-rgb));
    --bs-danger: rgb(var(--bs-danger-rgb));

    /* Semi-transparent color variables */
    --bs-black-rgba: rgba(var(--bs-black-rgb), 0.625);
    --bs-white-rgba: rgba(var(--bs-white-rgb), 0.25);

    /* Gradient color variables */
    --bs-gradient-primary: linear-gradient(
        180deg,
        var(--bs-primary-light) 0%,
        var(--bs-primary) 100%
    );

    --bs-gradient-secondary: linear-gradient(
        180deg,
        var(--bs-secondary-light) 0%,
        var(--bs-secondary) 100%
    );

    /**
     * Body
     *
     * Settings for the `<body>` element.
     */

    --bs-body-bg-rgb: var(--bs-white-rgb);
    --bs-body-color-rgb: var(--bs-black-rgb);
    --bs-body-bg: rgb(var(--bs-body-bg-rgb));
    --bs-body-color: rgb(var(--bs-body-color-rgb));
    --bs-body-semi-transparent: rgba(var(--bs-black-rgb), 0.0625);
    --bs-body-image: none;

    /**
     * Links
     *
     * Style anchor elements.
     */

    --bs-link-color: var(--bs-primary);
    --bs-link-decoration: none;

    /**
     * Highlight
     *
     * Style highlight elements.
     */

    --bs-highlight-color: var(--bs-primary);

    /**
     * Borders
     *
     * Style borders.
     */

    --bs-border-color: rgba(var(--bs-black-rgb), 0.0625);
    --bs-border-color-translucent: var(--bs-border-color);

    /**
     * Typography
     *
     * Font, line-height, and color for body text, headings, and more.
     */

    /* Font families (type) */
    --bs-font-base: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    --bs-font-sans-serif: 'Roboto', var(--bs-font-base);
    --bs-font-serif: 'Roboto Slab', Georgia, 'Times New Roman', Times, serif;
    --bs-font-alt: var(--bs-font-base);

    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;

    /* Font families (purpose) */
    --heading-font-family: var(--bs-font-sans-serif);
    --subhead-font-family: var(--bs-font-alt);

    /* Font sizes */
    --font-size-base: var(--bs-body-font-size);
    --font-size-xxs: 0.5625rem;
    --font-size-xs: 0.6875rem;
    --font-size-sm: 0.75rem;
    --font-size-md: 0.875rem;
    --font-size-lg: 1.0625rem;
    --font-size-xl: 1.25rem;
    --font-size-xxl: 1.375rem;

    @media only screen and (min-width: 768px) {
        --font-size-xxs: 0.5625rem;
        --font-size-xs: 0.6875rem;
        --font-size-sm: 0.75rem;
        --font-size-md: 0.9375rem;
        --font-size-lg: 1.0625rem;
        --font-size-xl: 1.25rem;
        --font-size-xxl: 1.5rem;
    }

    @media only screen and (min-width: 992px) {
        --font-size-xxs: 0.6875rem;
        --font-size-xs: 0.75rem;
        --font-size-sm: 0.875rem;
        --font-size-md: 1rem;
        --font-size-lg: 1.1875rem;
        --font-size-xl: 1.25rem;
        --font-size-xxl: 1.5rem;
    }

    /* Font weight map */
    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;

    /**
     * Spacing
     *
     * Control the default styling of most Bootstrap elements by modifying these
     * variables. Mostly focused on spacing.
     * You can add more entries to the var(--spacers) map, should you need more variation.
     */

    --spacer: 1rem;
    --spacer-0: 0;
    --spacer-1: calc(var(--spacer) * 0.25);
    --spacer-2: calc(var(--spacer) * 0.5);
    --spacer-3: calc(var(--spacer) * 1);
    --spacer-4: calc(var(--spacer) * 1.5);
    --spacer-5: calc(var(--spacer) * 2);
    --spacer-6: calc(var(--spacer) * 3);

    /**
     * Z-index master list
     *
     * Warning: Avoid customizing these values. They're used for a bird's eye view
     * of components dependent on the z-axis and are designed to all work together.
     */

    --zindex-dropdown: 1000;
    --zindex-sticky: 1020;
    --zindex-fixed: 1030;
    --zindex-offcanvas-backdrop: 1040;
    --zindex-offcanvas: 1045;
    --zindex-modal-backdrop: 1050;
    --zindex-modal: 1055;
    --zindex-popover: 1070;
    --zindex-tooltip: 1080;
    --zindex-toast: 1090;

    /**
     * Components
     *
     * Define common padding and border radius sizes and more.
     */

    /* Form options */
    --form-option-size: 1rem;
    --form-option-size-lg: 2.5rem;

    /**
     * Misc.
     *
     * Default values for miscellaneous styles
    */

    /* Shadows */
    --box-shadow: 0 0 0.75rem 0 rgba(var(--bs-black-rgb), 0.25);
    --box-shadow-light: 0 0 0.25rem rgba(var(--bs-black-rgb), 0.125);
    --box-shadow-inverse: 0 0 -0.75rem 0 rgba(var(--bs-black-rgb), 0.25);
    --box-shadow-alt: 0 0.75rem 0.75rem -0.75rem rgba(var(--bs-black-rgb), 1);

    /* Transitions */
    --transition-base-property: all;
    --transition-base-duration: 0.25s;
    --transition-base-timing: ease-in-out;
    --transition-base-delay: 0s;
    --transition-base: var(--transition-base-property)
        var(--transition-base-duration) var(--transition-base-timing)
        var(--transition-base-delay);
}
