.btn-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 2rem;
    min-height: 1rem;
    padding: 0.375rem;
    background-color: transparent;
    border: none;

    &,
    &:hover,
    &:active,
    &:focus {
        outline: none;
    }

    & span,
    &::before,
    &::after {
        display: block;
        width: 1.25rem;
        padding-top: 0.1875rem;
        margin-top: 0.3125rem;
        background-color: var(--bs-black);
        border-radius: 0;
    }

    &::before,
    &::after {
        content: '';
    }

    &::before {
        margin-top: 0;
    }

    [data-bs-theme='dark'] & {
        & span,
        &::before,
        &::after {
            background-color: var(--bs-white);
        }
    }
}
