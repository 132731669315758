html,
body {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
}

body {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
}
