/* Button with icon */
.btn-icon {
    position: relative;

    > .fa {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        color: inherit;
        right: 0.75rem;
        font-size: 1rem;
    }

    &.btn-lg {
        > .fa {
            right: 1rem;
            margin-top: -0.625rem;
            font-size: 1.25rem;
        }
    }
}
