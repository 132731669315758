.logo {
    display: block;
    height: 3.5rem;
    padding: 0;

    @media only screen and (min-width: 1200px) {
        height: 4rem;
    }

    &,
    &:active,
    &:visited,
    &:hover {
        text-decoration: none;
        background-color: transparent;
        border: none;
        outline: none;
    }

    &.bingoport-co-uk {
        height: 2.25rem;

        @media only screen and (min-width: 768px) {
            height: 2.75rem;
        }

        @media only screen and (min-width: 1200px) {
            height: 3.25rem;
        }
    }

    &.bingoport-co-uk.lg {
        height: 2.25rem;

        @media only screen and (min-width: 768px) {
            height: 4rem;
        }

        @media only screen and (min-width: 1200px) {
            height: 5rem;
        }
    }

    &.bingoport-com {
        height: 2.5rem;

        @media only screen and (min-width: 1200px) {
            height: 3rem;
        }
    }

    &.bingoport-com img {
        @media only screen and (max-width: 1199px) {
            transform: translateY(0.3125rem);
        }
    }

    picture,
    img {
        display: block;
        width: auto;
        height: 100%;
    }
}
