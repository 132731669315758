.label {
    display: inline-block;
    width: auto;
    padding: 0.75rem;
    margin: 0 auto 1rem;
    background-image: url('../../images/backgrounds/label.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: var(--bs-black);
    font-size: 1.125rem;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0.5rem;
}
