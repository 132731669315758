.icon-deposit {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
    font-size: 0.875rem;
    line-height: 1.2;
    text-align: center;

    &::before {
        flex: 0 0 auto;
        width: 2rem;
        height: 3rem;
        background-image: url('../../images/icons/deposit.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 2rem auto;
        content: '';
    }

    > div {
        margin-left: 0.25rem;
    }

    span {
        display: block;
    }

    b {
        color: var(--bs-gold);
    }

    &.icon-deposit--small {
        font-size: 0.75rem;
    }

    &.icon-deposit--small::before {
        width: 1.25rem;
        height: 2.325rem;
        background-size: 1.25rem auto;
    }

    &.icon-deposit--clockwise::before {
        transform: rotate(20deg);
    }

    &.icon-deposit--widdershins::before {
        transform: rotate(-20deg);
    }

    &.icon-deposit--bs-white {
        color: var(--bs-white);
    }

    &.icon-deposit--bs-white::before {
        background-image: url('../../images/icons/deposit-white.svg');
    }
}
