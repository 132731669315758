.listing {
    position: relative;
    margin-bottom: 0.5rem;
    border: none;
    color: var(--bs-white);
    overflow: hidden;

    @media only screen and (max-width: 767px) {
        margin-left: calc(-0.5 * var(--spacer-4));
        margin-right: calc(-0.5 * var(--spacer-4));

        &.listing--token {
            margin-left: 0;
            margin-right: 0;
        }
    }

    & + h2,
    & + h3,
    & + h4,
    & + .h2,
    & + .h3,
    & + .h4 {
        margin-top: 2rem;

        @media only screen and (min-width: 992px) {
            margin-top: 3rem;
        }
    }

    [class*='col'] {
        position: static;
    }

    &.listing--light .btn-xxl,
    &.listing--token .btn-xxl {
        font-size: 1.5rem;
        border-radius: 0.5rem;

        @media only screen and (min-width: 768px) {
            font-size: 1.25rem;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 1.5rem;
        }
    }

    &.listing--light .btn-xxl {
        padding: 0.5rem;
    }

    &.listing--token .btn-xxl {
        padding: 0.5rem 1rem;
    }
}

.listing__inner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    padding: 1rem;
    background: var(--listing-background);
    color: var(--listing-color);

    .icon-deposit {
        color: var(--listing-color);
    }

    .icon-deposit::before {
        background-image: var(--listing-deposit);
    }

    .icon-deposit b {
        color: var(--listing-bold);
    }
}

.listing__intro {
    font-size: 0.875rem;
}

.listing__image {
    display: block;

    img {
        max-width: 100%;
        border-radius: 0.5rem;
    }

    .listing--default &,
    .listing--default & img {
        aspect-ratio: 4 / 3;
        object-fit: cover;
    }
}

.listing__instructions {
    list-style: none;
    counter-reset: custom-counter;
    padding-left: 0;

    li {
        margin-bottom: 0.25rem;
    }

    li::before {
        content: counter(custom-counter) '.';
        counter-increment: custom-counter;
        margin-right: 0.25rem;
        font-weight: 700;
        color: var(--bs-red-light);
    }
}

.listing__title,
.listing__subtitle,
.listing__description,
.listing__terms {
    color: inherit;
}

.listing__description,
.listing__continue {
    font-size: 0.9375rem;
}

.listing__continue,
.listing__terms a {
    color: var(--listing-link);
}

.listing__title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.625rem;
    word-wrap: break-word;

    a {
        color: inherit;
        text-decoration: none;
    }

    .listing--default & {
        font-weight: 900;
    }

    .listing--enhanced & b {
        font-weight: 900;

        @media only screen and (max-width: 1199px) {
            font-size: 1.25rem;
            text-align: center;
        }
    }

    .listing--light & {
        color: var(--bs-gray-600);

        @media only screen and (min-width: 768px) {
            font-size: 1.25rem;
        }
    }

    .listing--token & {
        color: var(--bs-gray-600);
        font-weight: 700;
        text-transform: uppercase;
    }
}

.listing__subtitle {
    margin-top: -0.25rem;
    font-size: 1.25rem;

    @media only screen and (min-width: 768px) {
        font-size: 1.125rem;
    }

    .listing--light & {
        font-weight: 300;

        @media only screen and (min-width: 1200px) {
            font-size: 1.375rem;
        }
    }

    .listing--token & {
        color: var(--bs-red-light);
        font-size: 1.5rem;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.listing__iteration {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: var(--bs-white);
    color: var(--bs-black);
    font-size: 1.625rem;
    font-weight: 900;
    line-height: 1;
}

.listing__continue {
    display: block;
    text-decoration: underline;
    line-height: 1;
}

.listing__terms {
    color: var(--listing-terms);
    font-size: 0.75rem;
}

/* Grid-based listing layout */
.listing__grid {
    display: grid;
    grid-template-columns: 2fr 3fr; /* Default: 2 columns on mobile */
    gap: 0 1rem;

    @media only screen and (min-width: 768px) {
        grid-template-columns: 20% 1fr max-content; /* 3 columns on tablet */
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: 20% 1fr max-content; /* 3 columns on desktop */
        grid-template-rows: auto; /* Automatically adjust row height */
    }
}

.listing__intro-cell {
    grid-column: 1 / span 2;

    @media only screen and (min-width: 768px) {
        grid-column: 1 / span 3; /* Full width for cell 3 */
        order: 3;
    }

    @media only screen and (min-width: 1200px) {
        grid-column: 2 / span 2;
    }
}

.listing__button-cell {
    grid-column: 1 / span 2;

    @media only screen and (min-width: 768px) {
        grid-column: 3;
        order: 2;
    }
}

.listing__subtitle-cell {
    @media only screen and (min-width: 1200px) {
        grid-column: 2;
    }
}

.listing__image-cell {
    @media only screen and (min-width: 1200px) {
        grid-row: span 2;
    }
}
