h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    color: inherit;
    font-family: var(--heading-font-family);
    margin-top: 0;
    margin-bottom: 0.5rem;

    * + & {
        margin-top: 1rem;
    }

    @media only screen and (min-width: 768px) {
        * + & {
            margin-top: 1.5rem;
        }
    }

    @media only screen and (min-width: 1200px) {
        * + & {
            margin-top: 2rem;
        }
    }
}

h1 + h2,
h2 + h3,
h3 + h4 {
    margin-top: 0.5rem;

    @media only screen and (min-width: 768px) {
        margin-top: 1rem;
    }

    @media only screen and (min-width: 1200px) {
        margin-top: 1rem;
    }
}

h1,
.h1 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: clamp(1.125rem, 0.7672rem + 1.5267vw, 1.5rem);
    font-weight: 500;

    @media only screen and (min-width: 768px) {
        font-size: 1.5rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 1.625rem;
    }
}

h2,
.h2 {
    font-size: clamp(1.0625rem, 0.7643rem + 1.2723vw, 1.375rem);
    font-weight: 500;

    @media only screen and (min-width: 768px) {
        font-size: 1.375rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 1.5rem;
    }
}

h3,
.h3 {
    font-size: 1rem;

    @media only screen and (min-width: 768px) {
        font-size: 1.125rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 1.25rem;
    }
}

h4,
.h4 {
    font-size: 0.875rem;

    @media only screen and (min-width: 768px) {
        font-size: 0.9375rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 1rem;
    }
}

.heading-landing {
    color: var(--heading-landing-color);
    font-size: var(--heading-landing-font-size);
    font-weight: var(--heading-landing-font-weight);
    text-transform: uppercase;

    b,
    strong {
        color: var(--heading-landing-highlight-color);
        font-weight: inherit;
    }

    @media only screen and (min-width: 768px) {
        font-size: 2.5rem;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 2.75rem;
    }
}

.heading-landing-lg {
    font-size: 2.75rem;

    @media only screen and (min-width: 768px) {
        font-size: 2.75rem;
    }
}
