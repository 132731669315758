.poster {
    position: relative;
    display: block;
    padding-left: 2.25rem;
    padding-right: 2.25rem;

    > a {
        display: block;
    }

    @media only screen and (min-width: 768px) {
        padding-top: 0.5rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }

    @media only screen and (min-width: 1200px) {
        padding-top: 0.75rem;
        padding-left: 3.5rem;
        padding-right: 3.5rem;
    }
}
