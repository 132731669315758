.form-option {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: calc(var(--form-option-size) + 0.5rem);
    min-height: calc(var(--form-option-size) + 1rem);
}

.form-option-input {
    position: absolute;
    top: 50%;
    left: calc(var(--form-option-size) * 0.375);
    transform: translateY(-50%);
    opacity: 0;
}

.form-option-label {
    margin-bottom: 0;
    cursor: pointer;

    &::before,
    &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &::before {
        left: 0;
        width: var(--form-option-size);
        height: var(--form-option-size);
        border: 0.125rem solid var(--bs-black);
        content: '';
    }
}

.form-checkbox {
    .form-option-label::before {
        border-radius: calc(var(--form-option-size) * 0.25);
    }

    .form-option-label::after {
        left: calc(var(--form-option-size) * 0.375);
        width: calc(var(--form-option-size) * 0.3125);
        height: calc(var(--form-option-size) * 0.5);
        border-color: var(--bs-white);
        border-style: solid;
        border-width: 0 calc(var(--form-option-size) * 0.125)
            calc(var(--form-option-size) * 0.125) 0;
        transform: translateY(-60%) rotate(45deg);
    }

    .form-option-input:checked ~ .form-option-label::before {
        background-color: var(--bs-link-color);
    }
}

.form-radio {
    .form-option-label::before {
        border-radius: 50%;
    }
}

.form-checkbox,
.form-radio {
    .form-option-label::after {
        left: calc(var(--form-option-size) * 0.25);
        width: calc(var(--form-option-size) * 0.5);
        height: calc(var(--form-option-size) * 0.5);
        background-color: var(--bs-link-color);
        border-radius: 50%;
    }

    .form-option-input:focus ~ .form-option-label::before {
        box-shadow: none;
    }

    .form-option-input:checked ~ .form-option-label::before {
        border-color: var(--bs-link-color);
    }

    .form-option-input:checked ~ .form-option-label::after {
        content: '';
    }
}

.form-option-lg {
    padding-left: calc(var(--form-option-size-lg) + 1rem);
    min-height: calc(var(--form-option-size-lg) + 1rem);

    & .form-option-input {
        left: calc(var(--form-option-size-lg) * 0.375);
    }

    & .form-option-label::before {
        width: var(--form-option-size-lg);
        height: var(--form-option-size-lg);
    }

    &.form-checkbox .form-option-label::before {
        border-radius: calc(var(--form-option-size-lg) * 0.25);
    }

    &.form-checkbox .form-option-label::after {
        left: calc(var(--form-option-size-lg) * 0.3125);
        width: calc(var(--form-option-size-lg) * 0.3125);
        height: calc(var(--form-option-size-lg) * 0.5);
        border-width: 0 calc(var(--form-option-size-lg) * 0.125)
            calc(var(--form-option-size-lg) * 0.125) 0;
    }

    &.form-radio .form-option-label::after {
        left: calc(var(--form-option-size-lg) * 0.25);
        width: calc(var(--form-option-size-lg) * 0.5);
        height: calc(var(--form-option-size-lg) * 0.5);
    }
}

[data-bs-theme='dark'] .form-option,
.form-option-white {
    & .form-option-label {
        color: var(--bs-white);
    }

    & .form-option-label::before {
        border-color: var(--bs-white);
    }

    &.form-checkbox .form-option-label::after {
        border-color: var(--bs-white);
    }

    &.form-radio .form-option-label::after {
        background-color: var(--bs-white);
    }

    &.form-checkbox .form-option-input:checked ~ .form-option-label::before,
    &.form-radio .form-option-input:checked ~ .form-option-label::before {
        border-color: var(--bs-white);
    }
}
